import React from 'react'

import retrieveSavedAttributes from '../../lib/retrieveSavedAttribute'
import useIsMobile from '../../lib/utils/useIsMobile'

export default function CustomProductNew({ itemInfo, quantity, composition = false }) {
	let savedAttribute = retrieveSavedAttributes()
	const isMobile = useIsMobile()

	let info = itemInfo

	const { type, stepsOrder, lengths, notes, powerInfo, advanced } = info || {}

	// console.log(itemInfo)

	if (!info) return null

	// let stripName = ''
	// let profileName = ''
	// let stripCod = ''
	// let profileCod = ''
	// let isAeoss = false
	// let isLedpro = false
	// switch (savedAttribute) {
	// 	case 'led_pro':
	// 		isLedpro = true
	// 		if (strip && strip.selected) {
	// 			stripName = typeof strip.selected.name_ledpro === 'string' ? strip.selected.name_ledpro : strip.selected.name
	// 		}
	// 		if (profile && profile.selected) {
	// 			profileName = typeof profile.selected.name_ledpro === 'string' ? profile.selected.name_ledpro : profile.selected.name
	// 		}
	// 		break
	// 	case 'aeoss':
	// 		isAeoss = true
	// 		if (strip && strip.selected) {
	// 			stripName = strip.selected.name
	// 			stripCod = `${strip.selected.cod}.${color.selected.slug}`
	// 		}
	// 		if (profile && profile.selected) {
	// 			profileName = profile.selected.name
	// 			profileCod = `${profile.selected.cod}`
	// 		}
	// 		break
	// 	default:
	// 		isAeoss = true
	// 		if (strip && strip.selected) {
	// 			stripName = strip.selected.name
	// 		}
	// 		if (profile && profile.selected) {
	// 			profileName = profile.selected.name
	// 		}
	// }

	/*
    { "name": "Profilo", "type": "profile", "slug": "profiles" },
	{ "name": "Installazione", "type": "list", "listOrigin": "profiles", "slug": "installations" },
	{ "name": "Emissione", "type": "list", "listOrigin": "installations", "slug": "emissions" },
	{ "name": "Striscia", "type": "led_strips", "slug": "led_strips" },
	{ "name": "Temperatura colore", "type": "led_color", "listOrigin": "led_strips", "slug": "led_color" },
	{ "name": "Finiture", "type": "list", "listOrigin": "profiles", "slug": "finishings" },
	{ "name": "Coperture", "type": "list", "listOrigin": "profiles", "slug": "covers", "optional": true, "requiredOrigin": "profiles" },
	{ "name": "Trattamenti", "type": "list", "listOrigin": "profiles", "slug": "resins", "optional": true, "requiredOrigin": "profiles" },
	{ "name": "Temperatura colore di partenza", "type": "resins_extra_color", "slug": "resins_extra_color", "optional": true, "requiredOrigin": "resins" },
	{
		"name": "Tipologia Alimentazione",
		"type": "power_type",
		"slug": "power_type",
		"values": [
			{ "slug": "external", "name": "Alimentazione Esterna", "hasPowerSupply": true },
			{ "slug": "internal", "name": "Alimentazione Interna", "hasPowerSupply": true },
			{ "slug": "none", "name": "Nessuna Alimentazione", "hasPowerSupply": false }
		]
	},
	{
		"name": "Tipologia di dimmerazione",
		"type": "dimmer_type",
		"slug": "dimmer_type"
	},
	{
		"name": "Alimentatore",
		"type": "powerSupply",
		"slug": "power_supplies",
		"optional": true,
		"requiredOrigin": "power_type",
		"requiredSlug": "hasPowerSupply"
	},
	{
		"name": "Dimmer",
		"type": "dimmer",
		"slug": "dimmer",
		"optional": true,
		"requiredOrigin": "led_color"
	},
	{ "name": "Lunghezza cavo", "type": "selectList", "slug": "cables" },
	{ "name": "Colore cavo", "type": "selectList", "slug": "cable_finishings", "optional": true, "requiredOrigin": "profiles" },
	{ "name": "Posizione cavo", "type": "selectList", "slug": "cable_positions", "optional": true, "requiredOrigin": "profiles" }
    */

	switch (type) {
		case 'customStrip':
			return (
				<div>
					{info?.id && <h3 style={{ margin: '3px 3px 3px 0px' }}>ID: {info.id}</h3>}
					<h3 style={{ margin: '3px 3px 3px 0px' }}>
						{quantity ? `${quantity}x ` : ''}
						Striscia a misura:
					</h3>
					<div style={{ paddingLeft: '8px', fontSize: '14px' }}>
						{stepsOrder.map((stepSlug, i) => {
							let step = info[stepSlug]

							if (!step?.selected) return null

							return (
								<React.Fragment key={i}>
									<b>{step.name}:</b>&nbsp;{step.selected.qty && step.selected.qty !== 1 ? step.selected.qty + 'x ' : ''}
									{step.selected.cod ? step.selected.cod + ' ' : ''}
									{step.selected.name}
									<br />
								</React.Fragment>
							)
						})}

						{Array.isArray(lengths) &&
							lengths.map((length, i) => {
								return (
									<React.Fragment key={i}>
										{/* <b>Lunghezza{lengths.length > 1 ? ` #${i + 1}` : ''}:</b>&nbsp;{(length.value * (length.multiplier || 1)).toFixed(2)}
                                                    &nbsp;
                                                    {length.unit || 'cm'} */}
										<b>Lunghezza{lengths.length > 1 ? ` #${i + 1}` : ''}:</b>&nbsp;
										{length.value.toFixed(1).charAt(length.value.toFixed(1).length - 1) === '0'
											? length.value.toFixed(0)
											: length.value.toFixed(1)}{' '}
										mm
										<br />
									</React.Fragment>
								)
							})}

						<br />
						<div style={{ whiteSpace: 'break-spaces' }}>{notes}</div>
					</div>
				</div>
			)

		case 'customProfile':
			return (
				<div>
					{info?.id && <h3 style={{ margin: '3px 3px 3px 0px' }}>ID: {info.id}</h3>}
					<h3 style={{ margin: '3px 3px 3px 0px' }}>
						{quantity ? `${quantity}x ` : ''}
						Profilo a misura:
					</h3>
					<div style={{ paddingLeft: '8px', fontSize: '14px' }}>
						{stepsOrder.map((stepSlug, i) => {
							let step = info[stepSlug]

							if (!step?.selected) return null

							return (
								<React.Fragment key={i}>
									<b>{step.name}:</b>&nbsp;{step.selected.qty && step.selected.qty !== 1 ? step.selected.qty + 'x ' : ''}
									{step.selected.cod ? step.selected.cod + ' ' : ''}
									{step.selected.name}
									<br />
								</React.Fragment>
							)
						})}

						{Array.isArray(lengths) &&
							lengths.map((length, i) => {
								return (
									<React.Fragment key={i}>
										{/* <b>Lunghezza{lengths.length > 1 ? ` #${i + 1}` : ''}:</b>&nbsp;{(length.value * (length.multiplier || 1)).toFixed(2)}
                                                    &nbsp;
                                                    {length.unit || 'cm'} */}
										<b>Lunghezza{lengths.length > 1 ? ` #${i + 1}` : ''}:</b>&nbsp;
										{length.value.toFixed(1).charAt(length.value.toFixed(1).length - 1) === '0'
											? length.value.toFixed(0)
											: length.value.toFixed(1)}{' '}
										mm
										<br />
									</React.Fragment>
								)
							})}

						<br />
						<div style={{ whiteSpace: 'break-spaces' }}>{notes}</div>
					</div>
				</div>
			)

		case 'customLamp':
			return (
				<div>
					{info?.id && <h3 style={{ margin: '3px 3px 3px 0px' }}>ID: {info.id}</h3>}
					<h3 style={{ margin: '3px 3px 3px 0px' }}>
						{quantity ? `${quantity}x ` : ''}
						{composition ? 'Composizione lampade:' : 'Lampada a misura:'}
					</h3>
					<div style={{ paddingLeft: '8px', fontSize: '14px' }}>
						{stepsOrder.map((stepSlug, i) => {
							let step = info[stepSlug]

							if (!step?.selected) return null

							if (step.type === 'led_strips') {
								let colorStep = info['led_color']

								if (!Array.isArray(step.selected) || step.selected.lengths <= 0) return null
								if (advanced) {
									let lists = info.emission?.selected?.stripsList

									if (!Array.isArray(lists)) return null

									return (
										<React.Fragment key={i}>
											{lists.map((list, j) => {
												if (list.quantity <= 0) return null
												let strip = step.selected.find((sel) => sel.position === list.position)
												let color = colorStep.selected.find((sel) => sel.position === list.position)

												return (
													<React.Fragment key={j}>
														<b>
															{step.name} {list.position}:
														</b>
														&nbsp;{list.quantity !== 1 ? list.quantity + 'x ' : ''}
														{strip.name}
														<br />
														<b>
															{colorStep.name} {list.position}:
														</b>
														&nbsp;{color.name}
														<br />
													</React.Fragment>
												)
											})}
										</React.Fragment>
									)
								}
								return (
									<React.Fragment key={i}>
										<b>{step.name}:</b>&nbsp;{step.selected.length !== 1 ? step.selected.length + 'x ' : ''}
										{step.selected[0].name}
										<br />
										<b>{colorStep.name}:</b>&nbsp;{colorStep.selected[0].name}
										<br />
									</React.Fragment>
								)
							}
							if (step.type === 'led_color') {
								return null
							}

							return (
								<React.Fragment key={i}>
									<b>{step.name}:</b>&nbsp;{step.selected.qty && step.selected.qty !== 1 ? step.selected.qty + 'x ' : ''}
									{step.selected.cod ? step.selected.cod + ' ' : ''}
									{step.selected.name}
									<br />
								</React.Fragment>
							)
						})}

						{Array.isArray(lengths) &&
							lengths.map((length, i) => {
								return (
									<React.Fragment key={i}>
										{/* <b>Lunghezza{lengths.length > 1 ? ` #${i + 1}` : ''}:</b>&nbsp;{(length.value * (length.multiplier || 1)).toFixed(2)}
                                                            &nbsp;
                                                            {length.unit || 'cm'} */}
										<b>Lunghezza{lengths.length > 1 ? ` #${i + 1}` : ''}:</b>&nbsp;
										{length.value.toFixed(1).charAt(length.value.toFixed(1).length - 1) === '0'
											? length.value.toFixed(0)
											: length.value.toFixed(1)}{' '}
										mm
										<br />
									</React.Fragment>
								)
							})}

						<>
							<br />
							<b>Potenza richiesta:</b>&nbsp;
							<span style={{ whiteSpace: 'break-spaces' }}>{powerInfo.watt}W</span>
							<div style={{ whiteSpace: 'break-spaces' }}>{notes}</div>
						</>
					</div>
				</div>
			)
		default:
			return null
	}
}
