import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'

// import data from './newData.json'
// import baseSteps from './steps.json'
import Step from './newConfiguratorGraphic/Step'
// import fillData from './fillData'

import './newConfiguratorGraphic/styles.scss'
import NewPreview from './NewPreview'
import cloneDeep from 'lodash.clonedeep'

// TODO: filter psu (voltage, dimensioni, ecc), Add missing data (finishings, power supplies, ecc), add extra costs to prices (cuts, extra for large sizes)

function stringToMilimeters(numString, mode) {
	let numOnlyString = numString
		.trim()
		.replace(/[,]/gim, '.')
		.replace(/[^0-9.]/gim, '')

	let num = parseFloat(numOnlyString)

	if (numString.includes('mm')) {
		return num
	}

	// Always transform to mm
	if (num <= (mode === 'strip' ? 100 : 6)) {
		// Transform meters to mm
		num *= 1000
	} else if (num <= (mode === 'strip' ? 10000 : 600)) {
		// Transform cm to mm
		num *= 10
	}
	return num
}

function stringToUnit(numString, mode) {
	let numOnlyString = numString
		.trim()
		.replace(/[,]/gim, '.')
		.replace(/[^0-9.]/gim, '')

	let num = parseFloat(numOnlyString)

	if (numString.includes('mm')) {
		return { unit: 'mm', multiplier: 1 }
	}

	if (num <= (mode === 'strip' ? 100 : 6)) {
		return { unit: 'm', multiplier: 0.001 }
	} else if (num <= (mode === 'strip' ? 10000 : 600)) {
		return { unit: 'cm', multiplier: 0.1 }
	}
	return { unit: 'mm', multiplier: 1 }
}

export default function NewConfigurator({ mode = 'lamp', exportConfiguration = () => {}, closeFunction = () => {}, importData = null }) {
	//
	const [advanced, setAdvanced] = useState(false)
	const [lengths, setLengths] = useState([{ value: 1000, name: '100cm', multiplier: 0.1, unit: 'cm' }])
	const [lengthInput, setLengthInput] = useState('100cm')
	const lengthTimeout = useRef()
	const [prices, setPrices] = useState([])
	const [steps, setSteps] = useState()
	const [baseSteps, setBaseSteps] = useState()
	const [stepsData, setStepsData] = useState([])
	// const [accessories, setAccessories] = useState([])
	// const [powerSupplies] = useState([])
	const [voltage, setVoltage] = useState(null)
	const [powerInfo, setPowerInfo] = useState({})
	const [notes, setNotes] = useState('')
	const [data, setData] = useState({})
	const [dataLoaded, setDataLoaded] = useState(false)
	const [dataImported, setDataImported] = useState(false)

	useEffect(() => {
		if (dataLoaded) {
			if (Array.isArray(importData?.steps) && dataLoaded) {
				//
				let newSteps = steps.map((step) => {
					console.log(steps, importData)
					let importStep = importData.steps.find((s) => s.slug === step.slug)

					if (importStep) step.selected = importStep.selected

					return step
				})

				setSteps(newSteps)
				let newStepsData = getStepsData(newSteps)
				setStepsData(newStepsData)

				// Check voltage??

				let strip = newSteps.find((step) => step.slug === 'led_strips')
				if (Array.isArray(strip?.selected) && strip.selected.length > 0) setVoltage(strip.selected[0].volt)

				// set Length
				if (Array.isArray(importData.lengths) && importData.lengths.length > 0) {
					setLengths(importData.lengths)
					setLengthInput(importData.lengths[0].name)
				}
			}

			setDataImported(true)
		}

		// eslint-disable-next-line
	}, [dataLoaded, importData])

	console.log(steps)

	useEffect(() => {
		//
		fetch(`${process.env.REACT_APP_SERVER_URL}/api/configurator-products/data`)
			.then((res) => res.json())
			.then((data) => {
				if (Array.isArray(data?.products?.profiles)) {
					data.products.profiles = data.products.profiles.map((profile) => {
						let newStripsList = []
						if (Array.isArray(profile.installations)) {
							profile.installations.forEach((installation) => {
								if (installation.emissions) {
									installation.emissions.forEach((emission) => {
										if (Array.isArray(emission.stripsList)) {
											emission.stripsList.forEach((list) => {
												//
												let newStripsListIndex = newStripsList.findIndex((stripList) => stripList.position === list.position)
												if (newStripsListIndex === -1) {
													newStripsList.push({ position: list.position, items: [] })
													newStripsListIndex = newStripsList.length - 1
												}
												data.products.led_strips.forEach((led_strip) => {
													if (!newStripsList[newStripsListIndex].items.find((itemList) => itemList.cod === led_strip.cod)) {
														if (led_strip.pcb_width <= list.max_width) {
															newStripsList[newStripsListIndex].items.push(led_strip)
														}
													}
												})
											})
										}
									})
								}
							})
						}
						profile.stripsList = newStripsList

						return profile
					})
				}

				fetch(`${process.env.REACT_APP_SERVER_URL}/api/configurator-products/steps`)
					.then((res) => res.json())
					.then((dataStep) => {
						//

						setSteps(cloneDeep([...(dataStep[mode] ? dataStep[mode] : dataStep.lamps)]))
						setBaseSteps(dataStep)
						setData(data)
						setDataLoaded(true)
					})
			})

		// eslint-disable-next-line
	}, [])

	function filterDimmers(data, newSteps) {
		let dimmers = data.products.dimmers || []
		let profile = newSteps.find((step) => step.slug === 'profiles')
		let strip = newSteps.find((step) => step.slug === 'led_strips')
		let stripColor = newSteps.find((step) => step.slug === 'led_color')
		let volt = Array.isArray(strip?.selected) && strip.selected.length > 0 ? strip.selected[0].volt : 0
		let colors = Array.isArray(stripColor?.selected) ? stripColor.selected.map((col) => col.slug) : []

		let filteredDimmers = dimmers.filter((dim) => {
			if (dim.volt_min > volt || dim.volt_max < volt) return true
			// If an rgb / rgbw colos is selected check if dimmer supports it
			for (let i = 0; i <= colors.length; i++) {
				if (/rgbw/gimu.test(colors[i])) {
					if (!Array.isArray(dim.colors) || !dim.colors.find((col) => /rgbw/gimu.test(col))) return false
				}
				if (/rgb/gimu.test(colors[i])) {
					if (!Array.isArray(dim.colors) || !dim.colors.find((col) => /rgb/gimu.test(col))) return false
				}
			}

			return true
		})

		let filteredDimmersDimensions = filteredDimmers.filter((dim) => {
			if (
				!profile.selected ||
				!(
					(dim.width < profile.selected.width && dim.height < profile.selected.height) ||
					(dim.width < profile.selected.height && dim.height < profile.selected.width)
				)
			) {
				return false
			}

			return true
		})

		return [filteredDimmers, filteredDimmersDimensions]
	}

	function getStepsData(newSteps = steps) {
		// fill data
		let stepsData = []
		for (let i = 0; i < newSteps.length; i++) {
			let step = newSteps[i]
			if (step) {
				if (step.type === 'profile') {
					// console.log(data)
					stepsData.push(data.products[step.slug])
				} else if (step.type === 'led_strips') {
					if (mode === 'led_strip') {
						stepsData.push(data.products.led_strips)
					} else {
						//
						let profile = newSteps.find((step) => step.slug === 'profiles')
						// stripsList
						if (profile?.selected) {
							stepsData.push(profile.selected.stripsList)
						} else {
							stepsData.push(null)
						}
					}
				} else if (step.type === 'led_color') {
					// if step === color, custom logic (because of how strips are selected)
					if (mode === 'led_strip') {
						let stripIndex = newSteps.findIndex((step) => step.slug === 'led_strips')
						if (newSteps[stripIndex]?.selected?.colors) {
							stepsData.push(newSteps[stripIndex].selected.colors)
						} else {
							stepsData.push(null)
						}
					} else {
						let stripIndex = newSteps.findIndex((step) => step.slug === 'led_strips')
						let profile = newSteps.find((step) => step.slug === 'profiles')
						if (profile?.selected && steps[stripIndex]?.selected && steps[stripIndex].selected.length > 0) {
							// let colors = steps[stripIndex].selected.reduce((arr, strip) => [...arr, ...strip.colors], [])
							// colors = colors.filter((color, i) => colors.findIndex((col) => col.slug === color.slug) === i)
							stepsData.push(
								profile.selected.stripsList.map((list) => {
									let colors = list.items
										.filter((strip) =>
											steps[stripIndex].selected.find((selStrip) => selStrip.cod === strip.cod && selStrip.position === list.position)
										)
										// .reduce((arr, strip) => [...arr, ...strip.colors.map((col)=> ({...col, position: list.position, stripCod: strip.cod}))], [])
										.reduce((arr, strip) => [...arr, ...strip.colors], [])
									colors = colors.filter((color, i) => colors.findIndex((col) => col.slug === color.slug) === i)
									return { position: list.position, items: colors }
								})
							)
						} else {
							stepsData.push(null)
						}
					}
				} else if (step.type === 'power_type') {
					let ledColor = newSteps.find((step) => step.slug === 'led_color')
					let profile = newSteps.find((step) => step.slug === 'profiles')
					// if (!profile?.selected) {
					// 	stepsData.push(null)
					// }
					if (!Array.isArray(ledColor?.selected) || ledColor.selected.length <= 0) {
						stepsData.push(null)
					} else {
						let values = step.values

						// if profile has flag "internalPower"
						if (profile.selected.internalPower) {
							stepsData.push(values)
						} else {
							stepsData.push(values.filter((val) => val.slug !== 'internal'))
						}
					}
				} else if (step.type === 'dimmer_type') {
					let ledColor = newSteps.find((step) => step.slug === 'led_color')
					if (!Array.isArray(ledColor?.selected) || ledColor.selected.length <= 0) {
						stepsData.push(null)
					} else {
						let [filteredDimmers, internalFilteredDimmers] = filterDimmers(data, newSteps)
						let powerType = newSteps.find((step) => step.type === 'power_type')

						// if power type is set to no power
						if (!powerType?.selected || powerType.selected.slug === 'none') {
							stepsData.push(null)
						} else {
							let dimmerTypes = ['on/off']
							let pivotArray = powerType?.selected?.slug === 'internal' ? internalFilteredDimmers : filteredDimmers

							pivotArray.forEach((dim) => {
								if (Array.isArray(dim.dimmerType)) {
									dim.dimmerType.forEach((type) => {
										if (!dimmerTypes.includes(type)) dimmerTypes.push(type)
									})
								}
							})
							// Add psu dimmer types
							data.products.power_supplies.forEach((psu) => {
								if (Array.isArray(psu.dimmerTypes)) {
									psu.dimmerTypes.forEach((type) => {
										if (!dimmerTypes.includes(type)) dimmerTypes.push(type)
									})
								}
							})

							let typesData = data.products.dimmer_types
							dimmerTypes = dimmerTypes
								.map((type) => {
									let completeType = typesData.find((item) => item.slug === type)

									return completeType
								})
								.filter((value) => value)

							// console.log(powerType, pivotArray, dimmerTypes)

							stepsData.push(dimmerTypes)
						}
					}
				} else if (step.type === 'dimmer') {
					let ledColor = newSteps.find((step) => step.slug === 'led_color')
					if (!Array.isArray(ledColor?.selected) || ledColor.selected.length <= 0) {
						stepsData.push(null)
					} else {
						let [filteredDimmers, filteredDimmersDimensions] = filterDimmers(data, newSteps)
						let dimmerType = newSteps.find((step) => step.slug === 'dimmer_type')

						filteredDimmers = filteredDimmers.filter((dim) => {
							if (!Array.isArray(dim.dimmerType) || (dimmerType?.selected && !dim.dimmerType.includes(dimmerType.selected.slug))) {
								return false
							}
							return true
						})

						filteredDimmersDimensions = filteredDimmersDimensions.filter((dim) => {
							if (!Array.isArray(dim.dimmerType) || (dimmerType?.selected && !dim.dimmerType.includes(dimmerType.selected.slug))) {
								return false
							}
							return true
						})

						stepsData.push({
							dimmers: filteredDimmers,
							internalDimmers: filteredDimmersDimensions,
						})
					}
					//
				} else if (step.type === 'list') {
					let listStepIndex = newSteps.findIndex((s) => s.slug === step.listOrigin)
					let listStepSelected = newSteps[listStepIndex]?.selected
					if (listStepSelected) {
						stepsData.push(listStepSelected[step.slug])
					} else {
						stepsData.push(null)
					}
				} else if (step.type === 'selectList') {
					stepsData.push(data.products[step.slug])
				} else {
					if (step.type === 'powerSupply') {
						stepsData.push(data.products[step.slug])
					} else {
						stepsData.push(null)
					}
				}
			}
		}
		return stepsData
	}

	// function codFromConfiguration() {
	// 	if (dataLoaded && dataImported) {
	// 		let profile = steps.find((step) => step.slug === 'profiles')
	// 		let finishing = steps.find((step) => step.slug === 'finishings')
	// 		let length = lengths[0]
	// 		let cover = steps.find((step) => step.slug === 'covers')
	// 		let strip = steps.find((step) => step.slug === 'led_strips')
	// 		let color = steps.find((step) => step.slug === 'led_color')
	// 		let emission = steps.find((step) => step.slug === 'emissions')
	// 		// voltage
	// 		let dimmer_type = steps.find((step) => step.slug === 'dimmer_type')
	// 		let cod = `L.${profile?.selected?.cod}${'.' + finishing?.selected?.slug}${'.' + (length.value / 10).toFixed(0) + 'CM'}${
	// 			cover.selected ? `-${cover.selected.slug}` : ''
	// 		}${Array.isArray(strip.selected) && strip.selected.length > 0 ? `.${strip.selected[0].cod}` : ''}${
	// 			Array.isArray(color.selected) && color.selected.length > 0 ? `.${color.selected[0].slug}` : ''
	// 		}${'.' + emission?.selected?.slug}.${voltage}V${'.' + (dimmer_type?.selected?.slug === 'on/off' ? 'no' : dimmer_type?.selected?.slug)}`
	// 		return cod
	// 	}
	// 	return null
	// }

	// console.log('code: ', codFromConfiguration())

	function getConfigurationDataFromServer(newSteps = steps) {
		//

		axios
			.post(`${process.env.REACT_APP_SERVER_URL}/api/calculate-configuration/`, { steps: newSteps, lengths: lengths, mode: mode, voltage: voltage })
			.then((res) => {
				if (Array.isArray(res.data)) {
					if (Array.isArray(res.data[0])) setSteps(res.data[0])
					if (Array.isArray(res.data[1])) setPrices(res.data[1])
					// if (Array.isArray(res.data[2])) setAccessories(res.data[2])
					// if (res.data[3]) setPowerInfo({ watt: res.data[3], volt: voltage })
					if (res.data[4]) setPowerInfo(res.data[4])

					if (Array.isArray(res.data[0])) {
						let newStepsData = getStepsData(res.data[0])
						setStepsData(newStepsData)
					}
					let stripStep = res.data[0].find((step) => step.slug === 'led_strips')

					if (Array.isArray(stripStep?.selected) && stripStep.selected.length > 0) setVoltage(stripStep.selected[0].volt)
				}
			})
			.catch((e) => {
				console.error(e)
				toast.error(`si è verificato un errore nel calcolare la configurazione`)
			})
	}
	const calculateConfiguration = getConfigurationDataFromServer

	function toggleCollapse(step, i) {
		let newSteps = [...steps]
		newSteps[i].isCollapsed = !newSteps[i].isCollapsed
		setSteps(newSteps)
	}

	useEffect(() => {
		clearTimeout(lengthTimeout?.current)

		function calculateLength() {
			const newLengths = [{}]

			let milimetersString = stringToMilimeters(lengthInput, mode)
			let unit = stringToUnit(lengthInput, mode)

			newLengths[0].name = lengthInput
			newLengths[0].unit = unit.unit
			newLengths[0].multiplier = unit.multiplier

			if (milimetersString >= 0 && milimetersString <= 10000000) {
				newLengths[0].value = milimetersString
			}

			setLengths(newLengths)
		}

		if (dataLoaded && dataImported) {
			lengthTimeout.current = setTimeout(calculateLength, 1000)
		}
	}, [lengthInput, dataLoaded, dataImported, mode])

	useEffect(() => {
		if (dataLoaded && dataImported) {
			calculateConfiguration()
		}

		// eslint-disable-next-line
	}, [lengths, dataLoaded, dataImported])

	// useEffect(() => {
	// 	if (dataLoaded && dataImported) {
	// 		calculateConfiguration()
	// 	}
	// 	// getConfigurationDataFromServer()

	// 	// eslint-disable-next-line
	// }, [dataLoaded, dataImported])

	function saveConfiguration() {
		let error = ''
		let parsedSteps = steps.map((step) => {
			// Save space removing extra data
			if (step.selected) {
				if (step.slug === 'profiles') {
					step.selected.stripsList = []
					step.selected.installations = []
				}
			} else if (!step.optional) {
				error = 'Step Non opzionale non selezionato'
			}

			return step
		})

		if (error) {
			toast.error(error)
		} else {
			exportConfiguration(
				{ steps: parsedSteps, lengths: lengths, advanced: advanced, powerInfo: powerInfo, voltage: voltage, notes: notes, mode: mode, version: 3 },
				prices
			)
		}
		return parsedSteps
	}

	// function loadConfiguration() {}

	// console.log(steps, stepsData, prices, mode)

	return (
		<div className="new-configurator" style={{ overflow: 'auto', maxHeight: '100%' }}>
			<h1>Configura Il tuo Prodotto</h1>
			<div className="main-body">
				<div className="body-content">
					<div className="configurator-head-options">
						<div className="configurator-length">
							<div className="length-title">Lunghezza</div>
							<input
								className="length-input"
								value={lengthInput}
								onChange={(e) => {
									setLengthInput(e.target.value)

									// const newLengths = [{}]

									// let milimetersString = stringToMilimeters(e.target.value)
									// let unit = stringToUnit(e.target.value)

									// newLengths[0].name = e.target.value
									// newLengths[0].unit = unit.unit
									// newLengths[0].multiplier = unit.multiplier

									// if (milimetersString >= 0 && milimetersString <= 999999) {
									// 	newLengths[0].value = milimetersString
									// }

									// setLengths(newLengths)
								}}
							/>
						</div>
						{mode === 'lamp' && (
							<div className={`advanced-toggle ${advanced ? 'on' : ''}`}>
								<label className="configurator-toggle-label" htmlFor="advanced">
									Modalità Avanzata
								</label>
								<div className="configurator-toggle-container">
									<div className="configurator-toggle-off">Off</div>
									<div
										className={`configurator-toggle ${advanced ? 'checked' : ''}`}
										id="advanced"
										checked={advanced}
										onClick={() => {
											if (advanced) {
												// Remove strip && strip color selected
												let newSteps = [...steps]
												let stripIndex = newSteps.findIndex((step) => step.slug === 'led_strips')
												newSteps[stripIndex].selected = null

												let stripColorIndex = newSteps.findIndex((step) => step.slug === 'led_color')
												newSteps[stripColorIndex].selected = null

												calculateConfiguration(newSteps)
												setVoltage(null)
											}
											setAdvanced(!advanced)
										}}
									>
										<div className="configurator-toggle-index"></div>
									</div>
									<div className="configurator-toggle-on">On</div>
								</div>
							</div>
						)}
					</div>
					{dataLoaded &&
						Array.isArray(steps) &&
						steps.map((step, i) => {
							return (
								<Step
									key={i}
									steps={steps}
									advanced={advanced}
									step={step}
									i={i}
									stepsData={stepsData}
									calculateConfiguration={calculateConfiguration}
									toggleCollapse={toggleCollapse}
									mode={mode}
								/>
							)
						})}

					<div className="step">
						<h3 className="step-title">Note</h3>
						<textarea
							className={`step-notes`}
							placeholder="Inserire qui eventuali note..."
							value={notes}
							onInput={(e) => setNotes(e.target.value)}
						/>
					</div>
				</div>
				<div className="body-preview">
					<NewPreview
						lengths={lengths}
						advanced={advanced}
						steps={steps}
						powerInfo={powerInfo}
						price={Array.isArray(prices) && prices.length > 0 && !isNaN(prices[0]) ? prices[0].toFixed(2) : 0}
						saveConfiguration={saveConfiguration}
						mode={mode}
						closeFunction={closeFunction}
					/>
				</div>
			</div>
		</div>
	)
}
