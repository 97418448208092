import { useEffect, useState } from 'react'

import '../../styles/components/Suggestions.scss'

export default function SuggestionsModal({ importRows, confirmFunction = () => {}, closeModal = () => {} }) {
	//
	const [toRemove, setToRemove] = useState([])

	useEffect(() => {
		if (Array.isArray(importRows)) {
			setToRemove(importRows.map((row) => false))
		}
	}, [importRows])

	return (
		<div className="suggestion-modal">
			<button
				className="confirm-button"
				onClick={() => {
					confirmFunction(importRows.filter((_, i) => !toRemove[i]))
				}}
			>
				Conferma
			</button>
			<button
				className="confirm-button"
				onClick={() => {
					closeModal()
				}}
			>
				Annulla
			</button>
			<ul className="suggestion-list">
				{Array.isArray(importRows) &&
					importRows.map((row, i) => {
						return (
							<li
								key={i}
								className={`suggestion-row ${!toRemove[i] ? 'selected' : ''}`}
								onClick={() => {
									//
									let newRemoves = [...toRemove]
									newRemoves[i] = !newRemoves[i]
									setToRemove(newRemoves)
								}}
							>
								<div>{row.quantity ? row.quantity : 1}x</div>
								<div>{row.name}</div>
							</li>
						)
					})}
			</ul>
			<button
				className="confirm-button"
				onClick={() => {
					confirmFunction(importRows.filter((_, i) => !toRemove[i]))
				}}
			>
				Conferma
			</button>
			<button
				className="confirm-button"
				onClick={() => {
					closeModal()
				}}
			>
				Annulla
			</button>
		</div>
	)
}
