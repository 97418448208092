import ResinColorStep from './ResinColor'
import ProfileStep from './Profile'
import StripStep from './Strip'

export default function Step({ steps, advanced, step, i, stepsData, calculateConfiguration, setSteps, toggleCollapse, mode }) {
	switch (step.type) {
		case 'input':
			return null
		case 'resins_extra_color':
			return (
				<ResinColorStep
					steps={steps}
					advanced={advanced}
					step={step}
					i={i}
					stepsData={stepsData}
					calculateConfiguration={calculateConfiguration}
					toggleCollapse={toggleCollapse}
				/>
			)
		case 'led_color':
			return null
		case 'powerSupply':
		case 'dimmer':
			return null

		case 'profile':
			return (
				<ProfileStep
					steps={steps}
					advanced={advanced}
					step={step}
					i={i}
					stepsData={stepsData}
					calculateConfiguration={calculateConfiguration}
					toggleCollapse={toggleCollapse}
				/>
			)

		case 'led_strips':
			return (
				<StripStep
					steps={steps}
					advanced={advanced}
					step={step}
					i={i}
					stepsData={stepsData}
					calculateConfiguration={calculateConfiguration}
					toggleCollapse={toggleCollapse}
					mode={mode}
				/>
			)

		default:
			if (!stepsData[i]) return null
			return (
				<div className="step">
					<h3 className="step-title">{step.name}</h3>
					<div className="step-items">
						{Array.isArray(stepsData[i]) &&
							stepsData[i].map((item, j) => {
								let isSelected = false
								if (step.selected) {
									isSelected = step.selected.cod ? step.selected.cod === item.cod : step.selected.slug === item.slug
								}

								return (
									<div
										key={j}
										className={`step-item option-item ${isSelected ? 'selected' : ''}`}
										onClick={() => {
											let newSteps = [...steps]
											if (step.slug === 'emissions' || step.slug === 'installations') {
												let stripIndex = newSteps.findIndex((step) => step.slug === 'led_strips')
												newSteps[stripIndex].selected = null

												let stripColorIndex = newSteps.findIndex((step) => step.slug === 'led_color')
												newSteps[stripColorIndex].selected = null

												// calculateConfiguration(newSteps)
											}

											if (item?.cod) {
												if (step.selected?.cod !== item.cod) {
													newSteps[i].selected = item
													calculateConfiguration(newSteps)
												}
											} else {
												if (step.selected?.slug !== item.slug) {
													// select step
													newSteps[i].selected = item
													calculateConfiguration(newSteps)
												}
											}
										}}
									>
										{item.name}
										{/* {item?.cod ? item.cod : item.slug} - {item.name} */}
									</div>
								)
							})}
					</div>
				</div>
			)
	}
}
