import cloneDeep from 'lodash.clonedeep'

import productsStore from '../products'
import configurationStore from '../configurations'

import exportText from './exportFunctions/text'
import exportMarkdown from './exportFunctions/markdown'
// import exportPDF from './exportFunctions/pdf'
import exportMaterialListFunc from './exportFunctions/materialList'
import { download } from './exportFunctions/utils'

export default function exports(set, get) {
	return {
		exportDocument: () => {
			const store = cloneDeep(get())
			const document = store.document

			document.id = null
			document.quoteIdentifier = null
			document.identifier = null
			document.hasId = null
			document._id = null
			// document.userGroupId = null
			// document.initialUserGroupId = null

			const exportObj = {
				document: document,
				...store,
				quote: null,
				id: null,
				quoteIdentifier: null,
				identifier: null,
				hasId: null,
				_id: null,
				// userGroup_id: null,
				// userGroupId: null,
				// initialUserGroupId: null,
				verifyIfIsCorrentKey: 'VerifyKeyQuote',
				usedPriceList: productsStore.getState().selectedPriceList,
				attributeNames: configurationStore.getState().configurations?.attributeNames,
			}

			return exportObj
		},
		downloadDocument: () => {
			const store = get()
			const document = store.document

			document.id = null
			document.quoteIdentifier = null
			document.identifier = null
			document.hasId = null
			document._id = null
			document.userGroupId = null
			document.initialUserGroupId = null

			const exportObj = {
				document: document,
				...store,
				quote: null,
				id: null,
				quoteIdentifier: null,
				identifier: null,
				hasId: null,
				_id: null,
				userGroup_id: null,
				userGroupId: null,
				initialUserGroupId: null,
				verifyIfIsCorrentKey: 'VerifyKeyQuote',
				usedPriceList: productsStore.getState().selectedPriceList,
				attributeNames: configurationStore.getState().configurations?.attributeNames,
			}

			const now = new Date()
			const dd = String(now.getDate()).padStart(2, '0')
			const mn = String(now.getMonth() + 1).padStart(2, '0')
			const yyyy = now.getFullYear()
			const hh = String(now.getHours()).padStart(2, '0')
			const mm = String(now.getMinutes() + 1).padStart(2, '0')

			const today = yyyy + mn + dd + '_' + hh + mm
			const fileName = 'Preventivo_' + today + '.json'

			download(JSON.stringify(exportObj), fileName, 'text/plain')
		},
		importDocument: (file) => {
			const reader = new FileReader()
			reader.onload = async (e) => {
				const text = e.target.result

				// Parse File
				try {
					const importObj = JSON.parse(text)
					if (importObj.verifyIfIsCorrentKey === 'VerifyKeyQuote') {
						const document = importObj.document
						delete document.id
						delete document.quoteIdentifier
						// document.status = 'pending'

						set({ ...importObj, quote: null, id: null, quoteIdentifier: null, identifier: null, hasId: null, _id: null, document: document })
						if (importObj.usedPriceList) {
							let newPriceLists = {}
							let priceLists = productsStore.getState().priceLists
							let newSelectedPriceList = Array.isArray(priceLists) && priceLists.find((pl) => pl.slug === importObj.usedPriceList.slug)
							if (newSelectedPriceList) {
								newPriceLists.selectedPriceList = newSelectedPriceList
								productsStore.setState(newPriceLists)
							}
						}
						if (importObj.attributeNames) configurationStore.getState().setConfiguration('attributeNames', importObj.attributeNames)
						// return importObj.usedPriceList
					} else {
						alert('file Nor valid')
					}
				} catch (e) {
					console.error('file not valid', e)
					alert('file Nor valid')
				}
			}
			if (file) {
				reader.readAsText(file)
			}
		},

		exportText: () => {
			return exportText(get, set)
		},
		exportMarkdown: () => {
			return exportMarkdown(get, set)
		},
		exportPDFMake: (props) => {
			import('./exportFunctions/pdf')
				.then((exportPdfFunc) => {
					return exportPdfFunc.default(get, set, props)
				})
				.catch((err) => {
					// Handle failure
				})
		},
		// exportMaterialList: () => {
		// 	import('./exportFunctions/materialList')
		// 		.then((exportMaterialListFunc) => {
		// 			return exportMaterialListFunc.default(get, set)
		// 		})
		// 		.catch((err) => {
		// 			// Handle failure
		// 		})
		// },
		exportMaterialList: () => {
			exportMaterialListFunc(get, set)
		},
		exportMaterialListUnique: () => {
			exportMaterialListFunc(get, set, false)
		},
	}
}
