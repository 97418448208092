import { useState } from 'react'
import isChildOf from '../../../lib/isChildOf'

import './strips.scss'

function RowInfo({ strip }) {
	return (
		<>
			{/* <div>Image</div> */}
			<div className="strip-details-info">
				<div className="strip-text-info">
					<span>
						<b>{strip.cod}</b>
					</span>
					<span>
						<b>{strip.name}</b>
					</span>
					<span className="hide-small">{strip.led_m} LED/m</span>
					<span className="hide-small">{strip.lumen_m} lumen/m</span>
					<span>
						{strip.power_m}W/m - {strip.volt}V
					</span>
				</div>
				<div className="strip-details-colors">
					{Array.isArray(strip.colors) &&
						strip.colors.map((color, i) => {
							let colorString = typeof color === 'object' && color ? color.slug : color

							return <div key={i} className={`strip-color-option color-${colorString}`}></div>
						})}
				</div>
				<div className="strip-price">
					{strip.price.toFixed(1).replace(/\.0+$/, '')}€<span className="strip-option-price-unit">/m</span>
				</div>
			</div>
		</>
	)
}

export default function StripStep({ steps, advanced, step, i, stepsData, calculateConfiguration, setSteps, toggleCollapse, mode }) {
	const [stepFilterString, setStepFilterString] = useState('')
	const [filterData, setFilterData] = useState([])
	const [filterVisible, setFilterVisible] = useState(false)

	// console.log(mode)

	if (mode === 'led_strip') {
		let stripIndex = steps.findIndex((step) => step.slug === 'led_strips')
		let colorIndex = steps.findIndex((step) => step.slug === 'led_color')
		let array = stepsData[stripIndex]

		return (
			<>
				<div className="step">
					<h3
						className="step-title collapsable"
						onClick={() => {
							//
							toggleCollapse(step, i)
						}}
					>
						<div className={`collapse-toggle ${step.isCollapsed ? 'collapsed' : ''}`}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
								<path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
							</svg>
						</div>
						Striscia LED
					</h3>
					<div className="step-filter">
						<input
							value={stepFilterString}
							placeholder="Cerca..."
							onChange={(e) => {
								if (Array.isArray(array) && e.target.value) {
									let newData = array.reduce((arr, strip) => {
										if (strip.cod.toLowerCase().includes(e.target.value.toLowerCase())) {
											arr.push({ name: strip.name, item: strip })
											return arr
										}

										if (strip.name.toLowerCase().includes(e.target.value.toLowerCase())) {
											arr.push({ name: strip.name, item: strip })
											return arr
										}

										return arr
									}, [])

									setFilterData(newData)
								}

								setStepFilterString(e.target.value)
								setFilterVisible(true)
							}}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									//
									if (Array.isArray(filterData) && filterData.length > 0) {
										let item = filterData[0]
										let newSteps = [...steps]

										newSteps[i].selected = { ...item.item }

										calculateConfiguration(newSteps)
										setFilterVisible(false)
									}
								}
							}}
							onFocus={() => {
								setFilterVisible(true)
							}}
							onBlur={(e) => {
								if (!isChildOf(e.target.parentNode, e.relatedTarget)) {
									setFilterVisible(false)
								}
							}}
						/>

						<div className={`step-filter-list ${filterVisible ? '' : 'hidden'}`}>
							{Array.isArray(filterData) &&
								filterData.map((item, j) => {
									return (
										<div
											tabIndex="-1"
											className="step-filter-item"
											key={j}
											onClick={(e) => {
												let newSteps = [...steps]

												newSteps[i].selected = { ...item.item }

												calculateConfiguration(newSteps)
												setFilterVisible(false)
											}}
										>
											{item.name}
										</div>
									)
								})}
						</div>
					</div>
					<div className={`step-items ${step.isCollapsed ? 'collapsed' : ''}`}>
						{Array.isArray(array) &&
							array.map((item, j) => {
								let isSelected = step.selected?.cod === item.cod

								return (
									<div
										key={j}
										className={`step-item strip-item ${isSelected ? 'selected' : ''}`}
										onClick={() => {
											//
											if (!isSelected) {
												let newSteps = [...steps]

												newSteps[i].selected = { ...item }

												calculateConfiguration(newSteps)
											}
										}}
									>
										{<RowInfo strip={item} />}
										{/* {item?.cod ? item.cod : item.slug} - {item.name} */}
									</div>
								)
							})}
					</div>
				</div>
				{Array.isArray(stepsData[colorIndex]) && stepsData[colorIndex].length > 0 && (
					<div className="step">
						<h3 className="step-title">Colore Striscia LED</h3>
						<div className="step-items">
							{stepsData[colorIndex].map((color, j) => {
								let isSelected = steps[colorIndex].selected?.slug === color.slug

								return (
									<div
										key={j}
										className={`step-item option-item ${isSelected ? 'selected' : ''}`}
										onClick={() => {
											//
											let newSteps = [...steps]

											newSteps[colorIndex].selected = { ...color }

											calculateConfiguration(newSteps)
										}}
									>
										{color.name}
									</div>
								)
							})}
						</div>
					</div>
				)}
			</>
		)
	}

	let profile = steps.find((s) => s.slug === 'profiles').selected
	let emission = steps.find((step) => step.slug === 'emissions').selected
	let colorsIndex = steps.findIndex((s) => s.type === 'led_color')
	let defaultStripListIndex = profile?.stripsList?.findIndex((list) => list.default)
	if (defaultStripListIndex === -1) defaultStripListIndex = 0

	// console.log(profile, advanced)

	if (!profile) return null

	// console.log(advanced, profile.stripsList.length, colorsIndex, stepsData[colorsIndex])
	// if (!advanced || profile.stripsList.length <= 1) {
	if (!advanced) {
		// let array = [...stepData.down]
		let array =
			Array.isArray(profile.stripsList) && Array.isArray(profile.stripsList[defaultStripListIndex]?.items)
				? profile.stripsList[defaultStripListIndex].items
				: []

		return (
			<>
				<div className="step">
					<h3
						className="step-title collapsable"
						onClick={() => {
							//
							toggleCollapse(step, i)
						}}
					>
						<div className={`collapse-toggle ${step.isCollapsed ? 'collapsed' : ''}`}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
								<path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
							</svg>
						</div>
						Striscia LED
						{array.length <= 0 ? (
							<span>
								<sub> (selezionare prima un profilo)</sub>
							</span>
						) : (
							''
						)}
					</h3>
					<div className="step-filter">
						<input
							value={stepFilterString}
							placeholder="Cerca..."
							onChange={(e) => {
								if (Array.isArray(array) && e.target.value) {
									let newData = array.reduce((arr, strip) => {
										if (strip.cod.toLowerCase().includes(e.target.value.toLowerCase())) {
											arr.push({ name: strip.name, item: strip })
											return arr
										}

										if (strip.name.toLowerCase().includes(e.target.value.toLowerCase())) {
											arr.push({ name: strip.name, item: strip })
											return arr
										}

										return arr
									}, [])

									setFilterData(newData)
								}

								setStepFilterString(e.target.value)
								setFilterVisible(true)
							}}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									//
									if (Array.isArray(filterData) && filterData.length > 0) {
										let item = filterData[0]
										let newSteps = [...steps]

										let emission = steps.find((step) => step.slug === 'emissions').selected

										if (emission) {
											let newSelected = []
											emission.stripsList.forEach((list) => {
												if (list.quantity > 0) {
													newSelected.push({ ...item.item, position: list.position, quantity: list.quantity })
												}
											})

											newSteps[i].selected = newSelected

											calculateConfiguration(newSteps)
											setFilterVisible(false)
										}
									}
								}
							}}
							onFocus={() => {
								setFilterVisible(true)
							}}
							onBlur={(e) => {
								if (!isChildOf(e.target.parentNode, e.relatedTarget)) {
									setFilterVisible(false)
								}
							}}
						/>

						<div className={`step-filter-list ${filterVisible ? '' : 'hidden'}`}>
							{Array.isArray(filterData) &&
								filterData.map((item, j) => {
									return (
										<div
											tabIndex="-1"
											className="step-filter-item"
											key={j}
											onClick={(e) => {
												// select item
												let newSteps = [...steps]

												let emission = steps.find((step) => step.slug === 'emissions').selected

												if (emission) {
													let newSelected = []
													emission.stripsList.forEach((list) => {
														if (list.quantity > 0) {
															newSelected.push({ ...item.item, position: list.position, quantity: list.quantity })
														}
													})

													newSteps[i].selected = newSelected

													calculateConfiguration(newSteps)
												}

												setFilterVisible(false)
											}}
										>
											{item.name}
										</div>
									)
								})}
						</div>
					</div>
					<div className={`step-items ${step.isCollapsed ? 'collapsed' : ''}`}>
						{array.map((item, j) => {
							let isSelected = false
							if (Array.isArray(step.selected)) {
								isSelected = step.selected.find((selItem) => selItem.cod === item.cod)
							}

							return (
								<div
									key={j}
									className={`step-item strip-item ${isSelected ? 'selected' : ''}`}
									onClick={() => {
										//
										let selected = [...(Array.isArray(steps[i].selected) ? steps[i].selected : [])]

										let emission = steps.find((step) => step.slug === 'emissions').selected

										let selectedIndex = selected.findIndex((selItem) => selItem.cod === item.cod)
										if (selectedIndex === -1 && emission) {
											let newSelected = []
											emission.stripsList.forEach((list) => {
												if (list.quantity > 0) {
													newSelected.push({ ...item, position: list.position, quantity: list.quantity })
												}
											})

											let newSteps = [...steps]
											newSteps[i].selected = newSelected
											calculateConfiguration(newSteps)
										}
									}}
								>
									{<RowInfo strip={item} />}
									{/* {item?.cod ? item.cod : item.slug} - {item.name} */}
								</div>
							)
						})}
					</div>
				</div>
				{Array.isArray(stepsData[colorsIndex]) && stepsData[colorsIndex].length > 0 && (
					<div className="step">
						<h3 className="step-title">Colore Striscia LED</h3>
						<div className="step-items">
							{stepsData[colorsIndex][0].items.map((color, j) => {
								let isSelected = false
								if (steps[colorsIndex].selected) {
									isSelected = steps[colorsIndex].selected.find((selItem) => selItem.slug === color.slug)
								}

								return (
									<div
										key={j}
										className={`step-item option-item ${isSelected ? 'selected' : ''}`}
										onClick={() => {
											//
											let newSelections = []
											for (let j = 0; j < steps[i].selected.length; j++) {
												newSelections.push({
													...color,
													stripCod: steps[i].selected[j].cod,
													position: steps[i].selected[j].position,
												})
											}

											let newSteps = [...steps]
											newSteps[colorsIndex].selected = newSelections
											calculateConfiguration(newSteps)
										}}
									>
										{color.name}
									</div>
								)
							})}
						</div>
					</div>
				)}
			</>
		)
	}

	// console.log(advanced)
	if (advanced) {
		return (
			<>
				{profile.stripsList.map((list) => {
					// Find colors with same position as list
					let colorList =
						colorsIndex !== -1 &&
						Array.isArray(stepsData[colorsIndex]) &&
						stepsData[colorsIndex].find((colLis) => colLis.position === list.position)
					let emissionStripList =
						Array.isArray(emission.stripsList) &&
						emission.stripsList.find((emissionList) => emissionList.position === list.position && emissionList.quantity > 0)
					//

					if (!emissionStripList) return null

					return (
						<>
							<div className="step">
								<h3
									className="step-title collapsable"
									onClick={() => {
										//
										toggleCollapse(step, i)
									}}
								>
									<div className={`collapse-toggle ${step.isCollapsed ? 'collapsed' : ''}`}>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
											<path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
										</svg>
									</div>
									Striscia LED {list.position}
								</h3>
								<div className="step-filter">
									<input
										value={stepFilterString}
										placeholder="Cerca..."
										onChange={(e) => {
											if (Array.isArray(list.items) && e.target.value) {
												let newData = list.items.reduce((arr, strip) => {
													if (strip.cod.toLowerCase().includes(e.target.value.toLowerCase())) {
														arr.push({ name: strip.name, item: strip })
														return arr
													}

													if (strip.name.toLowerCase().includes(e.target.value.toLowerCase())) {
														arr.push({ name: profile.name, item: strip })
														return arr
													}

													return arr
												}, [])

												setFilterData(newData)
											}

											setStepFilterString(e.target.value)
											setFilterVisible(true)
										}}
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
												//
												if (Array.isArray(filterData) && filterData.length > 0) {
													let item = filterData[0]
													let newSteps = [...steps]

													let selected = [...(Array.isArray(steps[i].selected) ? steps[i].selected : [])]

													selected.push({ ...item.item, position: list.position, quantity: emissionStripList.quantity })

													newSteps[i].selected = selected
													calculateConfiguration(newSteps)

													//
												}
											}
										}}
										onFocus={() => {
											setFilterVisible(true)
										}}
										onBlur={(e) => {
											if (!isChildOf(e.target.parentNode, e.relatedTarget)) {
												setFilterVisible(false)
											}
										}}
									/>

									<div className={`step-filter-list ${filterVisible ? '' : 'hidden'}`}>
										{Array.isArray(filterData) &&
											filterData.map((item, j) => {
												return (
													<div
														tabIndex="-1"
														className="step-filter-item"
														key={j}
														onClick={(e) => {
															// select item
															let newSteps = [...steps]

															let selected = [...(Array.isArray(steps[i].selected) ? steps[i].selected : [])]

															selected.push({
																...item.item,
																position: list.position,
																quantity: emissionStripList.quantity,
															})

															newSteps[i].selected = selected
															calculateConfiguration(newSteps)
														}}
													>
														{item.name}
													</div>
												)
											})}
									</div>
								</div>
								<div className={`step-items ${step.isCollapsed ? 'collapsed' : ''}`}>
									{list.items.map((item) => {
										let isSelected = false
										if (step.selected) {
											isSelected = step.selected.find((selItem) => selItem.cod === item.cod && selItem.position === list.position)
										}

										return (
											<div
												className={`step-item strip-item ${isSelected ? 'selected' : ''}`}
												onClick={() => {
													let selected = [...(Array.isArray(steps[i].selected) ? steps[i].selected : [])]

													let emission = steps.find((step) => step.slug === 'emissions').selected

													let selectedIndex = selected.findIndex(
														(selItem) => selItem.cod === item.cod && selItem.position === list.position
													)
													if (selectedIndex === -1 && emission) {
														selected.splice(selectedIndex, 1)
														selected.push({ ...item, position: list.position, quantity: emissionStripList.quantity })

														let newSteps = [...steps]
														newSteps[i].selected = selected
														calculateConfiguration(newSteps)
													}
												}}
											>
												{<RowInfo strip={item} />}
												{/* {item?.cod ? item.cod : item.slug} - {item.name} */}
											</div>
										)
									})}
								</div>
							</div>
							{Array.isArray(colorList?.items) && colorList.items.length > 0 && (
								<div className="step">
									<h3 className="step-title">Colore Striscia LED {colorList.position}</h3>
									<div className="step-items">
										{colorList.items.map((color) => {
											let isSelected = false
											if (steps[colorsIndex].selected) {
												isSelected = steps[colorsIndex].selected.find(
													(selItem) => selItem.slug === color.slug && selItem.position === colorList.position
												)
											}

											return (
												<div
													className={`step-item option-item ${isSelected ? 'selected' : ''}`}
													onClick={() => {
														//
														let newSelections = [...steps[colorsIndex].selected]
														let selectedStrip = steps[i].selected.find((selStrip) => selStrip.position === colorList.position)
														let selectedStepIndex = newSelections.findIndex((selColor) => selColor.position === colorList.position)
														if (selectedStepIndex !== -1) {
															newSelections[selectedStepIndex] = {
																...color,
																stripCod: selectedStrip.cod,
																position: selectedStrip.position,
															}
														} else {
															newSelections.push({
																...color,
																stripCod: selectedStrip.cod,
																position: selectedStrip.position,
																quantity: emissionStripList.quantity,
															})
														}

														let newSteps = [...steps]
														newSteps[colorsIndex].selected = newSelections
														calculateConfiguration(newSteps)
													}}
												>
													{color.name}
												</div>
											)
										})}
									</div>
								</div>
							)}
						</>
					)
				})}
			</>
		)
	}
}
