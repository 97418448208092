import create from 'zustand'
import { persist } from 'zustand/middleware'
import axios from 'axios'
import IDBStorage from '../IDBStorage'
import useUserStore from './user'
import useConfigurationStore from './configurations'
import Fuse from 'fuse.js'

let serverUrl = process.env.REACT_APP_SERVER_URL

//TODO: Sync local document with db one
const initialProductsStore = {
	products: [],
	priceListsNames: [],
	priceLists: {},
	selectedPriceList: 'all',
	searchText: '',
	searchResults: [],
}

// Store for user Document, possible TODO: load document info from server??
const useProductsStore = create(
	persist(
		(set, get) => ({
			...initialProductsStore,
			clearStore: () => set({ ...initialProductsStore }),
			// clearSelectedPriceList: () => {
			// 	set({ selectedPriceList: 'all' })
			// },
			setSelectedPriceList: (priceList) => {
				set({ selectedPriceList: priceList })

				// const store = get()
				// store.priceListsNames.forEach((name) => {
				// 	if (name === priceList) {
				// 		// Update search results??

				// 		const updateObj = { selectedPriceList: priceList, indexedPriceList: priceList }
				// 		if (priceList !== store.indexedPriceList) {
				// 			console.log(store.products)
				// 			indexData(store.products)
				// 		}
				// 		set(updateObj)
				// 		return
				// 	}
				// })
			},
			fetchProducts: () => {
				// // check if user did not change between logins (data may change between users so data may need to be downloaded again)
				// const email = userStore && userStore.user && userStore.user.email
				// const hashEmail = sha256(email)
				// const cachedUser = store.cachedUser

				// if (hashEmail !== cachedUser) {
				// 	cachedDate = ''
				// 	// set({ cachedDate: cachedDate, cachedUser: hashEmail })
				// }

				const { token, user } = useUserStore.getState()

				let cachedDate = get().cacheDate

				axios
					.get(`${serverUrl}/api/products/new/${user && user.authLevel >= 750 ? 'admin/' : ''}${cachedDate ? cachedDate : ''}`, {
						headers: {
							authorization: token,
						},
					})
					.then((res) => {
						if (res.data) {
							let { products, priceLists, cached, cacheDate } = res.data

							if (!cached) {
								let selectedPriceList = get().selectedPriceList
								let toSelectPriceList = priceLists[0]
								let index = -1
								if (Array.isArray(priceLists)) {
									index = priceLists.findIndex((pl) => pl.slug === 'aeoss')
								}
								if (index !== -1) {
									toSelectPriceList = priceLists[index]
								}

								let newSelectedPriceList = selectedPriceList && priceLists.find((pl) => pl.slug === selectedPriceList.slug)
								if (!newSelectedPriceList) newSelectedPriceList = toSelectPriceList

								set({ products: products, priceListsNames: priceLists, selectedPriceList: newSelectedPriceList, cacheDate: cacheDate })
							}
						}
					})
					.catch((error) => {
						console.error(error)

						let setObj = {
							products: [],
							priceListsNames: [],
							selectedPriceList: null,
							cacheDate: null,
						}

						set(setObj)
						// throw error
					})
			},
			textSearch: (text) => {
				let data = get()
				let products = data.products

				const userStore = useUserStore.getState()
				const configurationStore = useConfigurationStore.getState()
				let showHidden = configurationStore.configurations.enableHiddenProducts

				const changableAttribute = userStore.user && userStore.user.changableAttribute
				let attributes = configurationStore.configurations && configurationStore.configurations.attributeNames
				if (!attributes) {
					attributes = userStore.user && userStore.user.attributeNames && userStore.user.attributeNames[0]
				}

				const searchTerms = text === '' ? [] : text.trim().replace(/,/gm, '.').toLowerCase().split(' ')

				if (searchTerms.length < 1 || searchTerms[0].length < 2) {
					set({ searchResults: [] })
					return
				}

				let searchComponents = []
				if (changableAttribute && configurationStore && configurationStore.configurations && configurationStore.configurations.allAttributes === true) {
					searchComponents = [
						{ name: 'name', weight: 2 },
						{ name: 'name_aeoss', weight: 2 },
					]
				} else {
					switch (attributes) {
						case 'led_pro':
							searchComponents = [{ name: 'name', weight: 2 }]
							break
						case 'aeoss':
							searchComponents = [{ name: 'name_aeoss', weight: 2 }]

							// Fix until name_aeoss / name_ledpro are added to product-manager
							// if (configurationStore.configurations.useNewProducts || (userStore.user && userStore.user.userType === 2)) {
							// 	searchComponents = [{ name: 'name', weight: 2 }]
							// }
							break
						default:
					}
				}
				searchComponents = [
					...searchComponents,
					{ name: 'cod', weight: 3 },
					{ name: 'sku', weight: 3 },
					{ name: 'category' },
					{ name: 'watt' },
					{ name: 'watt_meter' },
					{ name: 'watt_tot' },
					{ name: 'model' },
					{ name: 'finishing' },
					{ name: 'product_category.tags', weight: 2 },
					{ name: 'search_tags', weight: 2 },
				]

				// Set stuff for using new products instead of old ones (REMOVED OLD ONES)
				searchComponents.push({ name: 'product_id', weight: 3 }, { name: 'product_category_slug' }, { name: 'sku_manufacturer' })

				// product.cod, product.category, product.sku
				// product.led_ct, product.watt_meter, product.watt_tot, product.model, product.finishing, product.watt, product.dimensions, product.recess, product.lumen,
				const options = {
					isCaseSensitive: false,
					minMatchCharLength: 2,
					includeScore: true,
					keys: searchComponents,
					threshold: 0.05,
					findAllMatches: true,
					useExtendedSearch: false,
					ignoreLocation: true,
				}

				const fuse = new Fuse(
					products.filter((p) => !p.is_variant && (!p.hide_quote || showHidden)),
					options
				)

				let result = fuse.search(searchTerms[0]).map((el) => el.item)

				let arr = fuse.search(searchTerms[0])
				// console.log(arr)

				// Fuse search for each term
				for (let i = 1; i < searchTerms.length; i++) {
					const fuser = new Fuse(result, options)

					const term = searchTerms[i]

					if (term.length < 2) continue

					result = fuser.search(term).map((el) => el.item)
					arr = fuser.search(term)
				}

				// console.log(arr)
				// console.log(result)

				// result = arr.sort((a, b) => b.score - a.score).map((el) => el.item)
				result = arr
					.sort((a, b) => (a?.item?.hide_quote ? 1 : b?.item?.hide_quote ? -1 : a?.item?.cod.length - b?.item?.cod.length))
					.map((el) => el.item)

				/* sort results based on "search weight" ? maybe fuse weight * search weight as a % */

				let maxWeight = null

				for (let i = 0; i < result.length; i++) {
					if (result[i].search_weight) {
						if (maxWeight === null || maxWeight < result[i].search_weight) maxWeight = result[i].search_weight
					}
				}

				// let temp = arr.sort((a, b) => b.score - a.score)
				// .map((el) => {
				// 	return el.item
				// })

				// console.log(result, arr)

				set({
					searchText: text,
					searchResults: result.slice(0, 20),
				})
			},
			_hasHydrated: false,
		}),
		{
			name: 'products-storage',
			getStorage: () => IDBStorage,
			onRehydrateStorage: () => () => {
				useProductsStore.setState({ _hasHydrated: true })
			},
		}
	)
)

export default useProductsStore
