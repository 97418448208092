import create from 'zustand'
import { persist } from 'zustand/middleware'
import axios from 'axios'
import { toast } from 'react-toastify'

import useUserStore from './user'

import checkTokenError from '../checkTokenError'
import useProductsStore from './products'

let serverUrl = process.env.REACT_APP_SERVER_URL
//TODO: Sync local document with db one
const initialSuggestionsStore = {
	suggestions: [],
}

const colorSuggestions = {
	10: ['80', '35', '30', '13'],
	12: ['72', '70', '15', '76'],
	13: ['30', '35', '10', '80'],
	14: ['30', '67', '42'],
	15: ['70', '12', '72', '74'],
	20: ['60', '65', '86'],
	30: ['13', '35', '10', '80'],
	35: ['80', '30', '13', '10'],
	36: [],
	40: ['75', '41', '74'],
	41: ['40', '75', '42'],
	42: ['67', '14', '60'],
	ral9010: ['50', '55', '85'],
	50: ['55', 'ral9010', '85'],
	55: ['50', 'ral9010', '85'],
	60: ['65', '20', '86'],
	65: ['60', '20', '86'],
	67: ['42', '14', '60'],
	70: ['15', '12', '72'],
	72: ['12', '70', '15'],
	74: ['40', '75', '70'],
	75: ['40', '74', '41'],
	76: ['15', '70', '74'],
	80: ['10', '30', '35'],
	85: ['ral9010', '50', '55'],
	86: ['20', '60', '65'],
	90: [],
}

function calculateSuggestions(finishingCod, suggestionsArr) {
	//
	let prod
	let prodsArr = []
	suggestionsArr.forEach((suggProd) => {
		let codParts = suggProd.cod.split(/[.-]/g)

		if (codParts[1] === finishingCod) {
			prod = suggProd
		} else {
			prodsArr.push({ ...suggProd, colorCod: codParts[1] })
		}
	})
	if (prod) {
		//
		return prod
	}

	if (colorSuggestions[finishingCod]) {
		prodsArr.sort((a, b) => {
			let aIndex = colorSuggestions[finishingCod].findIndex((cod) => cod === a.colorCod)
			let bIndex = colorSuggestions[finishingCod].findIndex((cod) => cod === b.colorCod)
			// console.log(finishingCod, prodsArr, aIndex, bIndex, a.colorCod, b.colorCod)
			if (aIndex === -1) return 1
			if (bIndex === -1) return -1
			return aIndex - bIndex
		})

		// console.log(prodsArr, finishingCod)

		return prodsArr[0]
	}

	return null
}

// Store for user Document, possible TODO: load document info from server??
const useSuggestionsStore = create(
	persist(
		(set, get) => ({
			...initialSuggestionsStore,
			clearStore: () => set({ ...initialSuggestionsStore }),
			fetchSuggestions: async () => {
				const token = useUserStore.getState().token

				return axios
					.get(`${serverUrl}/api/suggestions/data`, {
						headers: {
							authorization: token,
						},
					})
					.then((res) => {
						set({ suggestions: res && res.data })
						return 'success'
					})
					.catch((error) => {
						console.error(error)

						let message = checkTokenError(error)
						if (!message) message = 'Errore caricando utenti'

						toast.error({
							render: message,
							type: 'error',
							autoClose: 5000,
							isLoading: false,
							closeOnClick: true,
							pauseOnHover: true,
						})

						set({ suggestions: [] })
						// throw error
					})
			},
			calculateSuggestionsRows: (items) => {
				let suggestions = get().suggestions
				let products = useProductsStore.getState().products
				let newRows = []
				let suggestCodsList = []
				items.forEach((item) => {
					if (item.product) {
						//
						let codParts = item.product.cod.split(/[.-]/g)
						let familyCod = codParts[0]
						let finishingCod = codParts[1]
						// let suggestionObj = suggestions.find((suggItem) => suggItem.product_id === familyCod)
						let suggestionObjArr = suggestions[familyCod]
						if (Array.isArray(suggestionObjArr)) {
							suggestionObjArr.forEach((suggestionObj) => {
								if (suggestionObj) {
									suggestCodsList.push({
										quantity: item.quantity,
										familyCod: familyCod,
										finishingCod: finishingCod,
										suggestionObj: suggestionObj,
										items: suggestionObj.items,
									})
								}
							})
						}
					}
				})
				if (suggestCodsList.length > 0) {
					suggestCodsList = suggestCodsList.map((list) => {
						let listRow = {
							...list,
							products: {},
						}
						products.forEach((product) => {
							let item = list.items.find((it) => {
								if (it.cod.includes('-')) {
									let codeParts = it.cod.split(/[.-]/g)
									let codeType = it.cod.split('-')
									if (codeType.length > 1) {
										codeType = codeType[1].split('.')[0]
										return product.cod.startsWith(codeParts[0]) && product.cod.includes(codeType)
									}
								} else {
									return product.cod.startsWith(it.cod)
								}

								return false
							})
							if (item) {
								//
								if (!Array.isArray(listRow.products[item.cod])) listRow.products[item.cod] = []
								listRow.products[item.cod].push(product)
							}
						})
						return listRow
					})
				}

				console.log(suggestCodsList)
				// console.log(suggestCodsList)
				suggestCodsList.forEach((list) => {
					//
					let productKeys = Object.keys(list.products)
					productKeys.forEach((key) => {
						// calculate correct suggestion (based on finishing)
						if (list.products[key].length === 1) {
							//
							newRows.push(list.products[key][0])
						} else {
							let prod = calculateSuggestions(list.finishingCod, list.products[key])
							if (prod) newRows.push(prod)
						}
					})
				})

				return newRows
			},
			calculateSuggestions: calculateSuggestions,
			// addSuggestions: (newRows)=> {
			//         if (newRows.length <= 0) {
			//             toast.info(`Nessun prodotto suggerito trovato`)
			//         } else {
			//             const items = [...zone.items]
			//             for (let i = 0; i < newRows.length; i++) {
			//                 let product = newRows[i]
			//                 let newItem = { product: product, quantity: newRows[i].quantity ? newRows[i].quantity : 1 }

			//                 newItem.name = productLabel(product)

			//                 // Set product && remove variant infos

			//                 // If profile, check for variants
			//                 if (product.category === 'profiles') {
			//                     let covers = getProductCovers(product)

			//                     if (Array.isArray(covers) && covers.length > 0) {
			//                         let opal = covers.find((cover) => /opal/gimu.test(cover.label))

			//                         if (opal) {
			//                             newItem.profileCover = opal.value
			//                         } else {
			//                             newItem.profileCover = covers[0].value
			//                         }
			//                     }

			//                     calculateItemPrice(newItem, productLabel, selectedPriceList)
			//                 } else {
			//                     newItem = calculateProductPrice(product, newItem, null, false, selectedPriceList)
			//                 }

			//                 items.push({ id: uuidv4(), quantity: 1, name: '', info: '', price: 0.0, length: 1, discount: '', ...newItem })
			//             }

			//             handleInput('items', items)
			//         }
			//     },
			_hasHydrated: false,
		}),
		{
			name: 'suggestions-storage',
			// getStorage: () => IDBStorage,
			// onRehydrateStorage: () => () => {
			// 	useSuggestionsStore.setState({ _hasHydrated: true })
			// },
		}
	)
)

export default useSuggestionsStore
