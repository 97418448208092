import { useEffect, useState } from 'react'
import { getUserGroups } from '../../lib/adminFunctions'
import Loading from '../graphic/Loading'
import axios from 'axios'
import useUserStore from '../../lib/stores/user'

import '../../styles/pages/Analytics.scss'

export default function Analytics() {
	const [analytics, setAnalytics] = useState()
	const [userGroups, setUserGroups] = useState([])
	const [loading, setLoading] = useState(true)
	const [products, setProducts] = useState(null)
	const [analyticsSorted, setAnalyticsSorted] = useState(false)
	const { token } = useUserStore((state) => ({ token: state.token }))

	useEffect(() => {
		//
		axios
			.get(`${process.env.REACT_APP_SERVER_URL}/api/quotes/analytics`, { headers: { authorization: token } })
			.then((res) => {
				//
				if (Array.isArray(res.data)) {
					// res.data.sort((a, b => a.))
					setAnalytics(res.data)
				}

				setLoading(false)
			})
			.catch((e) => console.error(e))

		axios
			.get(`${process.env.REACT_APP_SERVER_URL}/api/quotes/analytics/products`, { headers: { authorization: token } })
			.then((res) => {
				//
				if (res.data) {
					setProducts(res.data)
				}

				setLoading(false)
			})
			.catch((e) => console.error(e))
	}, [token])

	// Fetch userGroups on load
	useEffect(() => {
		getUserGroups().then((res) => {
			setUserGroups(res)
		})
	}, [])

	useEffect(() => {
		if (!analyticsSorted && Array.isArray(analytics) && Array.isArray(userGroups) && userGroups.length > 0) {
			//
			let newAnalytics = [...analytics]

			newAnalytics.sort((a, b) => {
				let aGroup = userGroups.find((group) => group._id === a._id)
				let bGroup = userGroups.find((group) => group._id === b._id)

				if (aGroup.code.startsWith('website')) return 1
				if (bGroup.code.startsWith('website')) return -1
				//

				return 0
			})

			setAnalyticsSorted(true)
			setAnalytics(newAnalytics)
		}
	}, [analytics, userGroups, analyticsSorted])

	// console.log(analytics)
	// console.log(userGroups)
	// console.log(products)

	return (
		<div className="list-list">
			<div className="list-actions">
				<div
					className="action-button"
					onClick={() => {
						setLoading(true)
						axios
							.get(`${process.env.REACT_APP_SERVER_URL}/api/quotes/analytics`, { headers: { authorization: token } })
							.then((res) => {
								//
								if (res.data) {
									setAnalytics(res.data)
								}

								setLoading(false)
							})
							.catch((e) => console.error(e))

						getUserGroups().then((res) => {
							setUserGroups(res)
						})
					}}
				>
					Sincronizza
				</div>
			</div>
			{/* <div style={{ display: 'flex', justifyContent: 'center' }}>
				<Filters data={quotes} searchString={searchString} setSearchString={setSearchString} />
				<div>
					<SelectUserGroup
						value={userGroupId}
						setValue={(option, index) => {
							if (userGroupId === option.value) {
								setUserGroupId(undefined)
							} else {
								setUserGroupId(option.value)
							}
						}}
					/>
				</div>
			</div> */}
			<div className="list-row row-header">
				<div className="list-column item-medium">Gruppo utente</div>
				<div className="list-column item-medium">Totale</div>
				<div className="list-column item-medium">Media</div>
				<div className="list-column item-medium">Media Sotto €10.000</div>
				<div className="list-column item-medium">Media Sotto €5.000</div>
			</div>
			{loading ? (
				<Loading />
			) : (
				Array.isArray(analytics) &&
				analytics.map((row, i) => {
					return (
						<div key={i} className="list-row">
							<div className="list-column item-medium">
								<div className="list-column-label">Gruppo utente</div>
								<div>
									{(Array.isArray(userGroups) &&
										userGroups.find((group) => group._id === row._id) &&
										userGroups.find((group) => group._id === row._id).code) ||
										'Gruppo non trovato'}
								</div>
							</div>
							<div className="list-column item-medium">
								<div className="list-column-label">Totale</div>
								<div>{row.total.toFixed(2)}</div>
							</div>
							<div className="list-column item-medium">
								<div className="list-column-label">Media</div>
								<div>{row.avarageTotal.toFixed(2)}</div>
							</div>
							<div className="list-column item-medium">
								<div className="list-column-label">Media Sotto €10.000</div>
								<div>{row.avarageTotal10k.toFixed(2)}</div>
							</div>
							<div className="list-column item-medium">
								<div className="list-column-label">Media Sotto €5.000</div>
								<div>{row.avarageTotal5k.toFixed(2)}</div>
							</div>
						</div>
					)
				})
			)}

			<table className="custom-table" style={{ textAlign: 'left' }}>
				<thead>
					<tr>
						<th>COD</th>
						<th>Qty</th>
						<th>N. preventivi</th>
						<th>Nome</th>
						<th>Categoria</th>
					</tr>
				</thead>
				<tbody>
					{Array.isArray(products) &&
						products.map((product) => {
							return (
								<tr>
									<td>{product.cod}</td>
									<td>{product.quantity}</td>
									<td>{product.quotesQuantity}</td>
									<td>{product.name}</td>
									<td>{product?.product_category?.name || 'nessuna categoria'}</td>
								</tr>
							)
						})}
				</tbody>
			</table>
		</div>
	)
}
