import getDiscount from '../getDiscount'
import calculateItemLength from './calculateItemLength'
import findVariant from './profilesVariants'

export function calculateProductPrice(product, item, multiplier = null, ignoreLength = false, selectedPriceList) {
	let productPrice
	let [length, lengthDisclaimer] = calculateItemLength(item)
	// console.log(product, item)

	// let priceListIndex = Array.isArray(product.pricelists)
	// 	? product.pricelists.findIndex((pricelist) => pricelist.pricelist_id === selectedPriceList?.pricelist_id)
	// 	: -1
	let priceListIndex = Array.isArray(product.pricelists) ? product.pricelists.findIndex((pricelist) => pricelist.slug === selectedPriceList?.slug) : -1
	if (priceListIndex === -1) {
		// priceListIndex = 0
		productPrice = 0
	} else {
		productPrice = (product.pricelists[priceListIndex].rel_product_pricelist && product.pricelists[priceListIndex].rel_product_pricelist.price) || 0
		productPrice = productPrice / 100
	}

	// console.log(selectedPriceList, priceListIndex, product)

	length = Math.ceil(length / 50) / 2

	// console.log(`lengths: ${length}`, `productPrice: ${productPrice}`, multiplier, typeof multiplier)

	if (multiplier !== null && !isNaN(parseFloat(multiplier))) {
		productPrice = productPrice * multiplier
		length = 1
	}

	if (ignoreLength) {
		length = 1
	}

	// calculate price
	item.price = productPrice
	item.price = Math.round(item.price * 100) / 100
	item.calculatedPrice = getDiscount(item.discount, item.quantity, item.price * length)
	item.total = item.calculatedPrice.tot
	item.priceCad = item.calculatedPrice.price
	item.lengthDisclaimer = lengthDisclaimer

	return item
}

export default function calculateItemPrice(item, productLabel, selectedPriceList) {
	let [length, lengthDisclaimer] = calculateItemLength(item)

	let profileLength = Math.ceil(length)

	length = Math.ceil(length / 50) / 2

	// calculate price
	item.calculatedPrice = getDiscount(item.discount, item.quantity, item.price * (item.variant ? 1 : length))

	item.total = item.calculatedPrice.tot
	item.priceCad = item.calculatedPrice.price
	item.lengthDisclaimer = lengthDisclaimer

	if (item.product && item.product.category === 'profiles') {
		let [variant, isCmLength] = findVariant(item.product, item.profileCover, profileLength)

		if (variant) {
			let variantChanged = false
			if (variant?.cod !== item.variant?.cod) {
				item.hasCustomPrice = false
				variantChanged = true
			}
			item.variant = variant
			item.isCmLength = isCmLength

			if (typeof productLabel === 'function' && variantChanged) item.name = productLabel(variant)

			// console.log(length, variant, isCmLength)

			if (!item.hasCustomPrice)
				item = calculateProductPrice(variant, item, isCmLength ? profileLength : null, variant && !isCmLength ? true : false, selectedPriceList)
		} else {
			item = calculateProductPrice(item.product, item, null, false, selectedPriceList)
		}
	}

	return item
}
