import { useEffect, useRef, useState } from 'react'

export default function ResinColorStep({ steps, advanced, step, i, stepsData, calculateConfiguration, setSteps, toggleCollapse }) {
	const [textInputValue, setTextInputValue] = useState(null)
	const textInputTimeout = useRef()

	useEffect(() => {
		// function update
		if (textInputValue !== null) {
			const newSteps = [...steps]
			if (!Array.isArray(newSteps[i].selected)) {
				newSteps[i].selected = [{}]
			}

			newSteps[i].selected = newSteps[i].selected.map((sel) => {
				return { ...sel, value: textInputValue, name: textInputValue, isChanged: true }
			})

			setSteps(newSteps)
		}
	}, [textInputValue])

	let requiredIndex = steps.findIndex((s) => s.slug === step.requiredOrigin)
	if (requiredIndex !== -1 && steps[requiredIndex].selected && steps[requiredIndex].selected[step.type]) {
		if (!advanced) {
			return (
				<div className="step">
					<h3 className="step-title">{step.name}</h3>
					<div className={`step-text-input-container`}>
						<textarea
							autoComplete="off"
							spellcheck="false"
							className="step-text-input"
							value={(Array.isArray(step.selected) && step.selected.length > 0 && step.selected[0].value) || ''}
							// value={textInputValue}
							onInput={(e) => {
								const newSteps = [...steps]
								if (!Array.isArray(newSteps[i].selected)) {
									newSteps[i].selected = [{}]
								}

								newSteps[i].selected = newSteps[i].selected.map((sel) => {
									return { ...sel, value: e.target.value, name: e.target.value, isChanged: true }
								})

								setSteps(newSteps)

								// setTextInputValue(e.target.value)
								// const newSteps = [...steps]
								// if (!newSteps[stepIndex].selected) {
								//     newSteps[stepIndex].selected = {}
								// }
								// newSteps[stepIndex].selected.value = e.target.value
								// newSteps[stepIndex].selected.name = e.target.value
								// newSteps[stepIndex].selected.isChanged = true
								// setSteps(newSteps)
							}}
						/>
						{/* cm */}
					</div>
				</div>
			)
		} else {
			let emission = steps.find((s) => s.slug === 'emissions')
			return (
				<>
					{emission.stripsList.map((list) => {
						let filteredSelected = Array.isArray(steps[i].selected) ? steps[i].selected.filter((sel) => sel.position === list.position) : []

						return (
							<div className="step">
								<h3 className="step-title">
									{step.name} - {list.position}
								</h3>
								<div className={`step-text-input-container`}>
									<textarea
										autoComplete="off"
										spellcheck="false"
										className="step-text-input"
										value={(Array.isArray(filteredSelected) && filteredSelected.length > 0 && filteredSelected[0].value) || ''}
										// value={textInputValue}
										onInput={(e) => {
											const newSteps = [...steps]
											if (!Array.isArray(newSteps[i].selected)) {
												newSteps[i].selected = [{}]
											}

											newSteps[i].selected = newSteps[i].selected.map((sel) => {
												if (sel.position === list.position)
													return { ...sel, value: e.target.value, name: e.target.value, isChanged: true }
											})

											setSteps(newSteps)
										}}
									/>
								</div>
							</div>
						)
					})}
				</>
			)
			// emission.stripsList.find((emissionList) => emissionList.position === list.position && emissionList.quantity > 0)
			// return null
		}
	}
	return null
}
