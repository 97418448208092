import { useState } from 'react'
import isChildOf from '../../../lib/isChildOf'

export default function ProfileStep({ steps, advanced, step, i, stepsData, calculateConfiguration, setSteps, toggleCollapse }) {
	const [stepFilterString, setStepFilterString] = useState('')
	const [filterData, setFilterData] = useState([])
	const [filterVisible, setFilterVisible] = useState(false)

	return (
		<div className="step">
			<h3
				className="step-title collapsable"
				onClick={() => {
					//
					toggleCollapse(step, i)
				}}
			>
				<div className={`collapse-toggle ${step.isCollapsed ? 'collapsed' : ''}`}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
						<path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
					</svg>
				</div>
				{step.name}
			</h3>
			<div className="step-filter">
				<input
					value={stepFilterString}
					placeholder="Cerca..."
					onChange={(e) => {
						if (Array.isArray(stepsData[i]) && e.target.value) {
							let newData = stepsData[i].reduce((arr, profile) => {
								if (profile.cod.toLowerCase().includes(e.target.value.toLowerCase())) {
									arr.push({ name: profile.name, item: profile })
									return arr
								}

								if (profile.name.toLowerCase().includes(e.target.value.toLowerCase())) {
									arr.push({ name: profile.name, item: profile })
									return arr
								}

								if (Array.isArray(profile.finishings)) {
									profile.finishings.forEach((finishing) => {
										if (`${profile.cod.toLowerCase()}.${finishing.slug}` === e.target.value.toLowerCase()) {
											arr.push({ name: `${profile.name} - ${finishing.name}`, item: profile, finishing: finishing })
										}
									})
								}

								return arr
							}, [])

							setFilterData(newData)
						}

						setStepFilterString(e.target.value)
						setFilterVisible(true)
					}}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							//
							if (Array.isArray(filterData) && filterData.length > 0) {
								let item = filterData[0]
								let newSteps = [...steps]
								newSteps[i].selected = item.item

								if (item.finishing) {
									let finishingIndex = steps.findIndex((step) => step.slug === 'finishings')
									if (finishingIndex !== -1) {
										newSteps[finishingIndex].selected = item.finishing
									}
								}
								calculateConfiguration(newSteps)
								setFilterVisible(false)
							}
						}
					}}
					onFocus={() => {
						setFilterVisible(true)
					}}
					onBlur={(e) => {
						if (!isChildOf(e.target.parentNode, e.relatedTarget)) {
							setFilterVisible(false)
						}
					}}
				/>

				<div className={`step-filter-list ${filterVisible ? '' : 'hidden'}`}>
					{Array.isArray(filterData) &&
						filterData.map((item, j) => {
							return (
								<div
									tabIndex="-1"
									className="step-filter-item"
									key={j}
									onClick={(e) => {
										// select item
										let newSteps = [...steps]
										newSteps[i].selected = item.item

										if (item.finishing) {
											let finishingIndex = steps.findIndex((step) => step.slug === 'finishings')
											if (finishingIndex !== -1) {
												newSteps[finishingIndex].selected = item.finishing
											}
										}
										calculateConfiguration(newSteps)
										setFilterVisible(false)
									}}
								>
									{item.name}
								</div>
							)
						})}
				</div>
			</div>
			<div className={`step-items ${step.isCollapsed ? 'collapsed' : ''}`}>
				{Array.isArray(stepsData[i]) &&
					stepsData[i].map((item, j) => {
						let isSelected = false
						if (step.selected) {
							isSelected = step.selected.cod === item.cod
						}

						return (
							<div
								key={j}
								className={`step-item profile-item ${isSelected ? 'selected' : ''}`}
								onClick={() => {
									if (item?.cod) {
										if (step.selected?.cod !== item.cod) {
											let newSteps = [...steps]

											let stripIndex = newSteps.findIndex((step) => step.slug === 'led_strips')
											if (stripIndex !== -1) newSteps[stripIndex].selected = null

											let stripColorIndex = newSteps.findIndex((step) => step.slug === 'led_color')
											if (stripColorIndex !== -1) newSteps[stripColorIndex].selected = null

											newSteps[i].selected = item
											calculateConfiguration(newSteps)
											// if (!step.isCollapsed) toggleCollapse(step, i)
										}
									} else {
										if (step.selected?.slug !== item.slug) {
											// select step
											let newSteps = [...steps]

											let stripIndex = newSteps.findIndex((step) => step.slug === 'led_strips')
											if (stripIndex !== -1) newSteps[stripIndex].selected = null

											let stripColorIndex = newSteps.findIndex((step) => step.slug === 'led_color')
											if (stripColorIndex !== -1) newSteps[stripColorIndex].selected = null
											newSteps[i].selected = item
											calculateConfiguration(newSteps)
											// if (!step.isCollapsed) toggleCollapse(step, i)
										}
									}
								}}
							>
								<div className="profile-image">
									<img src={`./images/profiles/${item.image}`} alt={item.imageAlt} />
								</div>
								{/* <span className="profile-cod">{item.cod}</span> */}
								<span className="profile-name">{item.name}</span>
								<span className="profile-description">{item.description}</span>
							</div>
						)
					})}
			</div>
		</div>
	)
}
