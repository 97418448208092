/* eslint-disable no-useless-escape */
// eslint identifies \. in regex as useless escape
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDebounce } from 'rooks'
import { v4 as uuidv4 } from 'uuid'
// import { toast } from 'react-toastify'
import { FloatingDelayGroup } from '@floating-ui/react-dom-interactions'
import axios from 'axios'
import isEqual from 'lodash.isequal'
import Select from 'react-select'
import { toast } from 'react-toastify'

import ContentEditable from '../ContentEditable'
import CustomProduct from './CustomProducts'
import CustomProductNew from './CustomProductsNew'
import Configurator from '../extra/Configurator'
import ConfiguratorOld from '../extra/Configurator_old'

import isChildOf from '../../lib/isChildOf'
import useProductsStore from '../../lib/stores/products'
import useModalStore from '../../lib/stores/modal'
import useUserStore from '../../lib/stores/user'
import retrieveSavedAttributes from '../../lib/retrieveSavedAttribute'
import useConfigurationStore from '../../lib/stores/configurations'
import Tooltip from '../graphic/Tooltip'
// import EditAdvancedConfiguration from '../extra/EditAdvancedConfiguration'
import exportConfiguratorProductAsText from '../../lib/utils/exportConfiguratorProductAsText'
import { getProductCovers } from '../../lib/utils/profilesVariants'
import useUtilsStore from '../../lib/stores/utils'
import NewConfigurator from '../extra/NewConfigurator'
import { parseCustomProductNew, unparseCustomProductNew } from '../../lib/utils/parseCustomProduct'
import useSuggestionsStore from '../../lib/stores/suggestions'
// import cloneDeep from 'lodash.clonedeep'

export default function ZoneItem({
	item,
	i,
	items,
	provided,
	handleItemInput,
	setItem,
	copyItem,
	addItem,
	deleteItem,
	selectItem,
	selectedInput,
	setSelectedInput,
	productLabel,
	autoselect = true,
	parseCustomProduct,
	addSuggestions,
}) {
	const { addModal, removeModal } = useModalStore((state) => ({ addModal: state.addModal, removeModal: state.removeModal }))
	const { textSearch, searchResults, selectedPriceList, products } = useProductsStore((state) => ({
		textSearch: state.textSearch,
		searchResults: state.searchResults,
		selectedPriceList: state.selectedPriceList,
		products: state.products,
	}))
	const { calculateSuggestionsRow } = useSuggestionsStore((state) => ({ calculateSuggestionsRow: state.calculateSuggestionsRows }))
	const { configurations } = useConfigurationStore((state) => ({ configurations: state.configurations }))
	const { token } = useUserStore((state) => ({ token: state.token }))
	const { setHoveredItem } = useUtilsStore((state) => ({ setHoveredItem: state.setHoveredItem }))
	const [actionsOpen, setActionsOpen] = useState(false)

	const searchProductDebouced = useDebounce(searchProduct, 200)

	const firstInputRef = useRef()
	const searchInputRef = useRef()

	// Auto select the quantity input
	useEffect(() => {
		if (firstInputRef && firstInputRef.current && autoselect) {
			firstInputRef.current.focus()
			firstInputRef.current.select()
		}
	}, [autoselect])

	// calculate profile covers
	const covers = useMemo(() => {
		return getProductCovers(item?.product)
	}, [item?.product])

	function searchProduct(text, input) {
		textSearch(text)
	}

	// Handle keyboard navigation
	function handleKeyDownMacros(event, index, type, product) {
		if (event.ctrlKey) {
			switch (event.key) {
				case 'q':
					event.preventDefault()
					setSelectedInput(-1)
					break
				case 'Enter':
					event.preventDefault()
					addItem()
					break
				default:
			}
		} else {
			switch (event.key) {
				case 'Escape':
					event.preventDefault()
					if (type === 'list') {
						if (event.target.parentNode.previousElementSibling) {
							event.target.parentNode.previousElementSibling.focus()
						}
					}
					setSelectedInput(-1)
					break
				case 'Enter':
					if (product) {
						event.preventDefault()
						selectItem(index, product)
						setSelectedInput(-1)
						if (searchInputRef.current) searchInputRef.current.focus()
					}
					break
				case 'ArrowUp':
					if (type === 'list') {
						if (event.target.previousElementSibling && typeof event.target.previousElementSibling.focus === 'function') {
							event.preventDefault()
							event.target.previousElementSibling.focus()
						}
					}

					break
				case 'ArrowDown':
					if (type === 'list') {
						if (event.target.nextElementSibling && typeof event.target.nextElementSibling.focus === 'function') {
							event.preventDefault()
							event.target.nextElementSibling.focus()
						}
					} else if (type === 'input') {
						if (
							event.target.nextElementSibling &&
							event.target.nextElementSibling.firstChild &&
							typeof event.target.nextElementSibling.firstChild.focus === 'function'
						) {
							event.preventDefault()
							event.target.nextElementSibling.firstChild.focus()
						}
					}
					// if (selectedInput !== index) {
					// 	setSelectedInput(index)
					// }
					break
				default:
			}
		}
	}

	// console.log(covers)

	// Calculate recent modified by find & replace util
	let recentlyModified
	if (item.dataModifiedByFunction && !configurations.hideAlert) {
		let modifiedDate = new Date(item.dataModifiedByFunction)
		let now = new Date()

		if (now.getTime() - modifiedDate.getTime() <= 1000 * 60 * 60 * 24 * 7) {
			recentlyModified = true
		}
	}

	return (
		<div
			ref={provided ? provided.innerRef : null}
			{...(provided ? provided.draggableProps : {})}
			className="zone-product"
			tabIndex="-1"
			onMouseLeave={(e) => {
				setActionsOpen(false)
			}}
			onMouseEnter={() => {
				setHoveredItem(item)
			}}
			// style={{ alignSelf: item && item.product ? 'start' : 'center' }}
			// style={{ outline: 'none' }}
		>
			<div className="zone-product-entry small" style={{ alignSelf: item && item.product ? 'start' : 'center' }}>
				<label className="zone-product-entry-label" htmlFor={`quantity#${item.id}`}>
					Qty
				</label>
				<input
					autoComplete="off"
					ref={firstInputRef}
					// autoFocus
					className="zone-product-input"
					id={`quantity#${item.id}`}
					name={`quantity#${item.id}`}
					label={'Qty'}
					value={item.quantity || ''}
					onKeyDown={(e) => handleKeyDownMacros(e, i)}
					onChange={(e) => {
						e.preventDefault()
						if (e.target.value.match(/^[+-]?[0-9]*[\.,]?[0-9]*$/gimu)) {
							handleItemInput(i, 'quantity', e.target.value.replace(',', '.'))
						}
					}}
					validate={(value) => {
						if (value.match(/^[+-]?[0-9]*[\.,]?[0-9]*$/gimu)) {
							return true
						}

						return false
					}}
				/>
			</div>
			{item.type === 'customProductNew' ? (
				<>
					<div className="zone-product-entry custom">
						<label className="zone-product-entry-label" htmlFor={`product#${item.id}`}>
							Prodotto
						</label>
						<div
							className="zone-product-input zone-product-input-info"
							// innerRef={this.contentEditable}
							onKeyDown={(e) => handleKeyDownMacros(e, i)}
						>
							<CustomProductNew itemInfo={item.productInfo} />
						</div>
					</div>
					<div className="zone-product-entry edit-button">
						<div>
							<label className="zone-product-entry-label" htmlFor={`info#${item.id}`}>
								Copy
							</label>
							<div className="zone-product-edit-custom">
								<div
									className="edit-custom-icon icon-hover"
									onClick={() => {
										// let text = exportConfiguratorProductAsText(item.productInfo)
										// navigator.clipboard.writeText(text)
									}}
								>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
										<path fill="none" d="M0 0h24v24H0z" />
										<path d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.006-1H7zM5.002 8L5 20h10V8H5.002zM9 6h8v10h2V4H9v2zm-2 5h6v2H7v-2zm0 4h6v2H7v-2z" />
									</svg>
								</div>
							</div>
						</div>
						<div>
							<label className="zone-product-entry-label" htmlFor={`info#${item.id}`}>
								Edit
							</label>
							<div className="zone-product-edit-custom">
								<div
									className="edit-custom-icon icon-hover"
									onClick={() => {
										if (typeof addModal === 'function') {
											let importData = unparseCustomProductNew(item.productInfo)
											// let savedAttribute = retrieveSavedAttributes()
											addModal({
												modal: (
													<div style={{ width: '85vw', height: '85vh' }}>
														<NewConfigurator
															importData={importData}
															mode={item.productInfo.mode}
															closeFunction={() => removeModal('ConfiguratorModal')}
															exportConfiguration={async (productData, prices) => {
																/*
                                                                	let productInfo = parseCustomProduct(productData)

																	productInfo.type = item.productInfo.type
																	productInfo.id = item.productInfo.id

																	// console.log(
																	// 	cloneDeep(productInfo),
																	// 	cloneDeep(item.productInfo),
																	// 	isEqual(productInfo, item.productInfo),
																	// 	JSON.stringify(productInfo) === JSON.stringify(item.productInfo),
																	// 	JSON.stringify(productInfo),
																	// 	JSON.stringify(item.productInfo)
																	// )

																	if (!isEqual({ ...productInfo, id: null }, { ...item.productInfo, id: null })) {
																		let productRes = await axios.post(
																			`${process.env.REACT_APP_SERVER_URL}/api/configurator-products/`,
																			{
																				price: price,
																				...productInfo,
																			},
																			{
																				headers: {
																					authorization: token,
																				},
																			}
																		)

																		if (productRes && productRes.data) {
																			productInfo.id = productRes.data.id
																		}
																	}

																	// handleItemInput(i, 'productInfo', productInfo)
																	handleItemInput(i, ['productInfo', 'price'], [productInfo, price])

																	removeModal('ConfiguratorModal')
                                                                */

																//
																// console.log(steps)
																// let productInfo = parseCustomProductNew(productData)
																let productInfo = parseCustomProductNew(productData)

																productInfo.type = item.productInfo.type
																productInfo.id = item.productInfo.id

																let price = 0
																if (Array.isArray(prices)) {
																	prices.forEach((p) => {
																		price += p
																	})
																}

																if (!isEqual({ ...productInfo, id: null }, { ...item.productInfo, id: null })) {
																	let productRes = await axios.post(
																		`${process.env.REACT_APP_SERVER_URL}/api/configurator-products/`,
																		{
																			price: price,
																			...productInfo,
																		},
																		{
																			headers: {
																				authorization: token,
																			},
																		}
																	)

																	if (productRes && productRes.data) {
																		productInfo.id = productRes.data.id
																	}
																}

																handleItemInput(i, ['productInfo', 'price'], [productInfo, price])
																removeModal('ConfiguratorModal')
															}}
														/>
													</div>
												),
												id: 'ConfiguratorModal',
												background: true,
												replaceModal: true,
												disposableModal: true,
											})
										} else {
											console.log('addModal not funciton?', addModal)
										}
									}}
								>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
										<path fill="none" d="M0 0h24v24H0z" />
										<path d="M5 19h1.414l9.314-9.314-1.414-1.414L5 17.586V19zm16 2H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L9.243 19H21v2zM15.728 6.858l1.414 1.414 1.414-1.414-1.414-1.414-1.414 1.414z" />
									</svg>
								</div>
							</div>
						</div>
					</div>
					<div className="zone-product-entry medium-large" style={{ alignSelf: item && item.product ? 'start' : 'center' }}>
						<label className="zone-product-entry-label" htmlFor={`info#${item.id}`}>
							Info
						</label>
						<ContentEditable
							className="zone-product-input zone-product-input-info"
							// innerRef={this.contentEditable}
							value={item.info || ''}
							onKeyDown={(e) => handleKeyDownMacros(e, i)}
							onInput={(e) => {
								handleItemInput(i, ['info', 'infoPlainText'], [e.target.value, e.target.htmlAsText])
							}}
						/>
					</div>
				</>
			) : item.type === 'customProduct' ? (
				<>
					<div className="zone-product-entry custom">
						<label className="zone-product-entry-label" htmlFor={`product#${item.id}`}>
							Prodotto
						</label>
						<div
							className="zone-product-input zone-product-input-info"
							// innerRef={this.contentEditable}
							onKeyDown={(e) => handleKeyDownMacros(e, i)}
						>
							<CustomProduct itemInfo={item.productInfo} />
						</div>
					</div>
					<div className="zone-product-entry edit-button">
						<div>
							<label className="zone-product-entry-label" htmlFor={`info#${item.id}`}>
								Copy
							</label>
							<div className="zone-product-edit-custom">
								<div
									className="edit-custom-icon icon-hover"
									onClick={() => {
										let text = exportConfiguratorProductAsText(item.productInfo)

										navigator.clipboard.writeText(text)
									}}
								>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
										<path fill="none" d="M0 0h24v24H0z" />
										<path d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.006-1H7zM5.002 8L5 20h10V8H5.002zM9 6h8v10h2V4H9v2zm-2 5h6v2H7v-2zm0 4h6v2H7v-2z" />
									</svg>
								</div>
							</div>
						</div>
						<div>
							<label className="zone-product-entry-label" htmlFor={`info#${item.id}`}>
								Edit
							</label>
							<div className="zone-product-edit-custom">
								<div
									className="edit-custom-icon icon-hover"
									onClick={() => {
										if (typeof addModal === 'function') {
											let savedAttribute = retrieveSavedAttributes()
											addModal({
												modal: (
													<div style={{ width: '85vw', height: '85vh' }}>
														{item.productInfo && item.productInfo.version !== 2 ? (
															<ConfiguratorOld
																importInfo={item.productInfo}
																exportFunction={async ({ price, ...productData }) => {
																	let productInfo = parseCustomProduct(productData)

																	productInfo.type = item.productInfo.type
																	productInfo.id = item.productInfo.id

																	// console.log(
																	// 	cloneDeep(productInfo),
																	// 	cloneDeep(item.productInfo),
																	// 	isEqual(productInfo, item.productInfo),
																	// 	JSON.stringify(productInfo) === JSON.stringify(item.productInfo),
																	// 	JSON.stringify(productInfo),
																	// 	JSON.stringify(item.productInfo)
																	// )

																	if (!isEqual({ ...productInfo, id: null }, { ...item.productInfo, id: null })) {
																		let productRes = await axios.post(
																			`${process.env.REACT_APP_SERVER_URL}/api/configurator-products/`,
																			{
																				price: price,
																				...productInfo,
																			},
																			{
																				headers: {
																					authorization: token,
																				},
																			}
																		)

																		if (productRes && productRes.data) {
																			productInfo.id = productRes.data.id
																		}
																	}

																	// handleItemInput(i, 'productInfo', productInfo)
																	handleItemInput(i, ['productInfo', 'price'], [productInfo, price])

																	removeModal('ConfiguratorModal')
																}}
																closeFunction={() => removeModal('ConfiguratorModal')}
																attributeInfo={savedAttribute}
																selectedPriceList={selectedPriceList}
															/>
														) : (
															<Configurator
																importInfo={item.productInfo}
																exportFunction={async ({ price, ...productData }) => {
																	let productInfo = parseCustomProduct(productData)

																	productInfo.type = item.productInfo.type
																	productInfo.id = item.productInfo.id

																	// console.log(
																	// 	cloneDeep(productInfo),
																	// 	cloneDeep(item.productInfo),
																	// 	isEqual(productInfo, item.productInfo),
																	// 	JSON.stringify(productInfo) === JSON.stringify(item.productInfo),
																	// 	JSON.stringify(productInfo),
																	// 	JSON.stringify(item.productInfo)
																	// )

																	if (!isEqual({ ...productInfo, id: null }, { ...item.productInfo, id: null })) {
																		let productRes = await axios.post(
																			`${process.env.REACT_APP_SERVER_URL}/api/configurator-products/`,
																			{
																				price: price,
																				...productInfo,
																			},
																			{
																				headers: {
																					authorization: token,
																				},
																			}
																		)

																		if (productRes && productRes.data) {
																			productInfo.id = productRes.data.id
																		}
																	}

																	// handleItemInput(i, 'productInfo', productInfo)
																	handleItemInput(i, ['productInfo', 'price'], [productInfo, price])

																	removeModal('ConfiguratorModal')
																}}
																closeFunction={() => removeModal('ConfiguratorModal')}
																attributeInfo={savedAttribute}
																selectedPriceList={selectedPriceList}
															/>
														)}
													</div>
												),
												id: 'ConfiguratorModal',
												background: true,
												replaceModal: true,
												disposableModal: true,
											})
										} else {
											console.log('addModal not funciton?', addModal)
										}
									}}
								>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
										<path fill="none" d="M0 0h24v24H0z" />
										<path d="M5 19h1.414l9.314-9.314-1.414-1.414L5 17.586V19zm16 2H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L9.243 19H21v2zM15.728 6.858l1.414 1.414 1.414-1.414-1.414-1.414-1.414 1.414z" />
									</svg>
								</div>
							</div>
						</div>
						{/* {user && (user.authLevel >= 750 || (userApp && userApp.appAuthLevel >= 750)) && (
							<div>
								<label className="zone-product-entry-label">Edit Advanced</label>
								<div className="zone-product-edit-custom">
									<div
										className="edit-custom-icon icon-hover"
										onClick={() => {
											if (typeof addModal === 'function') {
												// let savedAttribute = retrieveSavedAttributes()
												addModal({
													modal: (
														<div style={{ width: '85vw', height: '85vh' }}>
															<EditAdvancedConfiguration
																item={cloneDeep(item)}
																exportFunc={async (newItem) => {
																	// console.log(newItem, isEqual(newItem, item))

																	if (!isEqual(newItem, item)) {
																		let productRes = await axios.post(
																			`${process.env.REACT_APP_SERVER_URL}/api/configurator-products/`,
																			{
																				...newItem.productInfo,
																			},
																			{
																				headers: {
																					authorization: token,
																				},
																			}
																		)

																		if (productRes && productRes.data) {
																			newItem.productInfo.id = productRes.data.id
																		}

																		setItem(newItem)
																	}

																	removeModal('editAdvancedConfigurationModal')
																}}
															/>
														</div>
													),
													id: 'editAdvancedConfigurationModal',
													background: true,
													replaceModal: true,
													disposableModal: true,
												})
											} else {
												console.log('addModal not funciton?', addModal)
											}
										}}
									>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
											<path d="M16.7574 2.99666L9.29145 10.4626L9.29886 14.7097L13.537 14.7023L21 7.2393V19.9967C21 20.5489 20.5523 20.9967 20 20.9967H4C3.44772 20.9967 3 20.5489 3 19.9967V3.99666C3 3.44438 3.44772 2.99666 4 2.99666H16.7574ZM20.4853 2.09717L21.8995 3.51138L12.7071 12.7038L11.2954 12.7062L11.2929 11.2896L20.4853 2.09717Z"></path>
										</svg>
									</div>
								</div>
							</div>
						)} */}
					</div>
					<div className="zone-product-entry medium-large" style={{ alignSelf: item && item.product ? 'start' : 'center' }}>
						<label className="zone-product-entry-label" htmlFor={`info#${item.id}`}>
							Info
						</label>
						<ContentEditable
							className="zone-product-input zone-product-input-info"
							// innerRef={this.contentEditable}
							value={item.info || ''}
							onKeyDown={(e) => handleKeyDownMacros(e, i)}
							onInput={(e) => {
								handleItemInput(i, ['info', 'infoPlainText'], [e.target.value, e.target.htmlAsText])
							}}
						/>
					</div>
				</>
			) : item.type === 'descriptiveItem' ? (
				<div className="zone-product-entry full">
					<label className="zone-product-entry-label" htmlFor={`product#${item.id}`}>
						Prodotto
					</label>
					<ContentEditable
						className="zone-product-input zone-product-input-info"
						// innerRef={this.contentEditable}
						value={item.name || ''}
						onKeyDown={(e) => handleKeyDownMacros(e, i)}
						onInput={(e) => {
							handleItemInput(i, ['name', 'namePlainText'], [e.target.value, e.target.htmlAsText])
						}}
					/>
				</div>
			) : (
				<>
					<div className="zone-product-entry large" style={{ alignSelf: item && item.product ? 'start' : 'center' }}>
						<label className="zone-product-entry-label" htmlFor={`product#${item.id}`}>
							Prodotto
						</label>

						<input
							autoComplete="off"
							ref={searchInputRef}
							style={item.showLength || (item.product && item.product.category === 'profiles') ? { width: '80%', marginRight: '0.4rem' } : {}}
							className={`zone-product-input ${item?.product?.hide_quote ? 'warning' : ''}`}
							type="search"
							id={`product#${item.id}`}
							name={`product#${item.id}`}
							value={item.name || ''}
							onBlur={(e) => {
								if (!isChildOf(e.target.nextElementSibling, e.relatedTarget)) {
									setSelectedInput(-1)
								}
							}}
							// onFocus={(e) => {
							// 	setSelectedInput(i)
							// 	searchProductDebouced(e.target.value, i)
							// }}
							onKeyDown={(e) => handleKeyDownMacros(e, i, 'input')}
							onChange={(e) => {
								setSelectedInput(i)

								handleItemInput(i, 'name', e.target.value)

								// searchProduct(e.target.value, i)
								searchProductDebouced(e.target.value, i)
							}}
							timer={500}
						/>
						{selectedInput === i && Array.isArray(searchResults) && searchResults.length > 0 && (
							<ul className="zone-product-search shadow-close">
								{searchResults.map((product, j) => {
									return (
										<li
											key={j}
											className={`zone-product-search-item ${product.hide_quote ? 'warning' : ''}`}
											tabIndex="-1"
											// style={{ width: '100%' }}
											onBlur={(e) => {
												// console.dir(e.relatedTarget)
												if (
													!isChildOf(e.target.parentNode, e.relatedTarget) &&
													e.target.parentNode.previousElementSibling !== e.relatedTarget
												) {
													setSelectedInput(-1)
												}
											}}
											onKeyDown={(e) => handleKeyDownMacros(e, i, 'list', product)}
											onClick={(e) => {
												selectItem(i, product)
												setSelectedInput(-1)
											}}
										>
											<div className="product-search-sku">{product.sku}</div>
											<div className="product-search-label">{productLabel(product)}</div>
											<div className="product-search-cod">{product.cod.replaceAll('.', '.\u200B')}</div>
											{/* {product.name} {} */}
										</li>
									)
								})}
							</ul>
						)}
						{(item.showLength || (item.product && item.product.category === 'profiles')) && (
							<>
								<label className="zone-product-entry-label" htmlFor={`productLength#${item.id}`}>
									Lunghezza
								</label>
								<input
									autoComplete="off"
									style={{ width: 'calc(20% - 0.4rem)', marginLeft: 0 }}
									className={`zone-product-input sub-input ${item.lengthDisclaimer ? 'input-attention' : ''}`}
									id={`productLength#${item.id}`}
									name={`productLength#${item.id}`}
									value={item.length || ''}
									onKeyDown={(e) => handleKeyDownMacros(e, i)}
									onChange={(e) => {
										e.preventDefault()
										if (/^([+-]?[0-9]*[.,]?[0-9]*)[ ]?[a-zA-Z]*$/gimu.test(e.target.value)) {
											handleItemInput(i, 'length', e.target.value.replace(',', '.'))
										}
									}}
									validate={(value) => {
										if (/^([+-]?[0-9]*[.,]?[0-9]*)[ ]?[a-zA-Z]*$/gimu.test(value)) {
											return true
										}
										return false
									}}
								/>
							</>
						)}
						{configurations && configurations.showExtra && (item.variant?.cod || item.product?.cod) && (
							<div className="zone-product-extras">
								<div
									className="product-extras-option"
									onClick={() => {
										navigator.clipboard.writeText(item.variant?.cod ? item.variant.cod : item.product.cod)
										// toast.info(`Cod ${item.product.cod} Copiato`)
									}}
								>
									<span>
										Cod: <b>{item.variant?.cod ? item.variant.cod : item.product.cod}</b>
									</span>
									<svg className="copy-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
										<path fill="none" d="M0 0h24v24H0z" />
										<path d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7zm2 0h8v10h2V4H9v2z" />
									</svg>
								</div>
								{(item.variant?.sku || item.product?.sku) && (
									<div
										className="product-extras-option"
										onClick={() => {
											navigator.clipboard.writeText(item.variant?.sku ? item.variant.sku : item.product.sku)
											// toast.info(`SKU ${item.product.sku} Copiato`)
										}}
									>
										<span>
											SKU: <b>{item.variant?.sku ? item.variant.sku : item.product.sku}</b>
										</span>
										<svg className="copy-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
											<path fill="none" d="M0 0h24v24H0z" />
											<path d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7zm2 0h8v10h2V4H9v2z" />
										</svg>
									</div>
								)}
							</div>
						)}
					</div>
					<div className="zone-product-entry medium-large" style={{ alignSelf: item && item.product ? 'start' : 'center' }}>
						<label className="zone-product-entry-label" htmlFor={`info#${item.id}`}>
							Info
						</label>
						<ContentEditable
							className="zone-product-input zone-product-input-info"
							// innerRef={this.contentEditable}
							value={item.info || ''}
							onKeyDown={(e) => handleKeyDownMacros(e, i)}
							onInput={(e) => {
								handleItemInput(i, ['info', 'infoPlainText'], [e.target.value, e.target.htmlAsText])
							}}
						/>
					</div>
				</>
			)}
			<div className="zone-product-entry medium" style={{ alignSelf: item && item.product ? 'start' : 'center' }}>
				<label className="zone-product-entry-label" htmlFor={`price#${item.id}`}>
					Prezzo
				</label>
				<input
					autoComplete="off"
					className="zone-product-input"
					id={`price#${item.id}`}
					name={`price#${item.id}`}
					step={0.1}
					value={item.price || ''}
					onKeyDown={(e) => handleKeyDownMacros(e, i)}
					onChange={(e) => {
						e.preventDefault()
						if (e.target.value.match(/^[+-]?[0-9]*[\.,]?[0-9]*$/gimu)) {
							handleItemInput(i, 'price', e.target.value.replace(',', '.'))
						}
					}}
					validate={(value) => {
						if (value.match(/^[+-]?[0-9]*[\.,]?[0-9]*$/gimu)) {
							return true
						}
						return false
					}}
				/>
			</div>
			<div className="zone-product-entry medium" style={{ alignSelf: item && item.product ? 'start' : 'center' }}>
				<label className="zone-product-entry-label" htmlFor={`discount#${item.id}`}>
					Sconto
				</label>
				<input
					autoComplete="off"
					className="zone-product-input"
					id={`discount#${item.id}`}
					name={`discount#${item.id}`}
					value={item.discount || ''}
					onKeyDown={(e) => {
						// console.log(e)
						if (i === items.length - 1 && e.key === 'Tab' && !e.shiftKey) {
							e.preventDefault()
							addItem()
						} else {
							handleKeyDownMacros(e, i)
						}
					}}
					onChange={(e) => {
						// e.preventDefault()
						handleItemInput(i, 'discount', e.target.value)
					}}
				/>
			</div>
			<div className="zone-product-entry medium zone-product-label-container" style={{ alignSelf: 'center' }}>
				<label className="zone-product-entry-label" htmlFor={`priceCad#${item.id}`}>
					Prezzo cad.
				</label>
				<span className="zone-product-input label" id={`priceCad#${item.id}`} name={`priceCad#${item.id}`} value={item.price}>
					{(!isNaN(parseFloat(item.priceCad)) && parseFloat(item.priceCad).toFixed(2)) || (0.0).toFixed(2)} €
				</span>
			</div>
			<div className="zone-product-entry medium zone-product-label-container" style={{ alignSelf: 'center' }}>
				<label className="zone-product-entry-label" htmlFor={`total#${item.id}`}>
					Total
				</label>
				<span className="zone-product-input label" name={`total#${item.id}`} onChange={() => {}}>
					{(!isNaN(parseFloat(item.total)) && parseFloat(item.total).toFixed(2)) || (0.0).toFixed(2)} €
				</span>
			</div>
			{provided && (
				<div
					className="zone-product-drag "
					{...provided.dragHandleProps}
					tabIndex="-1"
					style={{ alignSelf: item && item.product ? 'start' : 'center' }}
				>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="90%" height="2rem">
						{/* <path fill="none" d="M0 0h24v24H0z" />
					<path d="M11 11V5.828L9.172 7.657 7.757 6.243 12 2l4.243 4.243-1.415 1.414L13 5.828V11h5.172l-1.829-1.828 1.414-1.415L22 12l-4.243 4.243-1.414-1.415L18.172 13H13v5.172l1.828-1.829 1.415 1.414L12 22l-4.243-4.243 1.415-1.414L11 18.172V13H5.828l1.829 1.828-1.414 1.415L2 12l4.243-4.243 1.414 1.415L5.828 11z" /> */}
						<path fill="none" d="M0 0h24v24H0z" />
						<path d="M12 3c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 14c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-7c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
					</svg>
				</div>
			)}
			{recentlyModified && (
				<div className={`zone-product-alert`}>
					<FloatingDelayGroup delay={{ open: 700, close: 400 }}>
						<Tooltip label="Riga modificata nell'ultima settimana da una utilità" placement="top">
							<div className={`icon-hover active`} style={{ width: '26px', height: '26px', padding: '4px', cursor: 'default' }}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z" />
								</svg>
							</div>
						</Tooltip>
					</FloatingDelayGroup>
				</div>
			)}
			<div className={`zone-product-actions ${actionsOpen ? 'open' : 'close'}`}>
				<div className="zone-product-actions-icon icon-hover" style={{ width: '100%', height: '100%' }} onClick={(e) => setActionsOpen(!actionsOpen)}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22">
						<path fill="none" d="M0 0h24v24H0z" />
						<path d="M2 12c0-.865.11-1.703.316-2.504A3 3 0 0 0 4.99 4.867a9.99 9.99 0 0 1 4.335-2.505 3 3 0 0 0 5.348 0 9.99 9.99 0 0 1 4.335 2.505 3 3 0 0 0 2.675 4.63c.206.8.316 1.638.316 2.503 0 .865-.11 1.703-.316 2.504a3 3 0 0 0-2.675 4.629 9.99 9.99 0 0 1-4.335 2.505 3 3 0 0 0-5.348 0 9.99 9.99 0 0 1-4.335-2.505 3 3 0 0 0-2.675-4.63C2.11 13.704 2 12.866 2 12zm4.804 3c.63 1.091.81 2.346.564 3.524.408.29.842.541 1.297.75A4.993 4.993 0 0 1 12 18c1.26 0 2.438.471 3.335 1.274.455-.209.889-.46 1.297-.75A4.993 4.993 0 0 1 17.196 15a4.993 4.993 0 0 1 2.77-2.25 8.126 8.126 0 0 0 0-1.5A4.993 4.993 0 0 1 17.195 9a4.993 4.993 0 0 1-.564-3.524 7.989 7.989 0 0 0-1.297-.75A4.993 4.993 0 0 1 12 6a4.993 4.993 0 0 1-3.335-1.274 7.99 7.99 0 0 0-1.297.75A4.993 4.993 0 0 1 6.804 9a4.993 4.993 0 0 1-2.77 2.25 8.126 8.126 0 0 0 0 1.5A4.993 4.993 0 0 1 6.805 15zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
					</svg>
				</div>
				<div className={`zone-product-actions-container`}>
					<div className={`zone-product-actions-list`}>
						<FloatingDelayGroup delay={{ open: 700, close: 400 }}>
							<Tooltip label="Aggiungi prodotti collegati" placement="top">
								<div className="zone-product-action">
									<div
										className={`icon-hover ${item.product ? '' : 'disabled'}`}
										onClick={() => {
											// let newRows = []

											// let suggestCodsList = []

											// if (item.product) {
											// 	//
											// 	let codParts = item.product.cod.split(/[.-]/g)
											// 	let familyCod = codParts[0]
											// 	let finishingCod = codParts[1]

											// 	let suggestionObj = suggestions.find((suggItem) => suggItem.product_id === familyCod)

											// 	if (suggestionObj) {
											// 		suggestCodsList.push({
											// 			quantity: item.quantity,
											// 			familyCod: familyCod,
											// 			finishingCod: finishingCod,
											// 			suggestionObj: suggestionObj,
											// 			items: suggestionObj.items,
											// 		})
											// 	}
											// }

											// if (suggestCodsList.length > 0) {
											// 	suggestCodsList = suggestCodsList.map((list) => {
											// 		let listRow = {
											// 			...list,
											// 			products: {},
											// 		}
											// 		products.forEach((product) => {
											// 			let item = list.items.find((it) => product.cod.startsWith(it.cod))
											// 			if (item) {
											// 				//
											// 				if (!Array.isArray(listRow.products[item.cod])) listRow.products[item.cod] = []
											// 				listRow.products[item.cod].push(product)
											// 			}
											// 		})
											// 		return listRow
											// 		// if (Object.keys(listRow.products).length > 0) newRows.push(listRow)
											// 	})
											// }

											// // console.log(suggestCodsList)

											// suggestCodsList.forEach((list) => {
											// 	//
											// 	let productKeys = Object.keys(list.products)

											// 	productKeys.forEach((key) => {
											// 		// calculate correct suggestion (based on finishing)
											// 		if (list.products[key].length === 1) {
											// 			//
											// 			newRows.push(list.products[key][0])
											// 		} else {
											// 			let prod = calculateSuggestions(list.finishingCod, list.products[key])
											// 			if (prod) newRows.push(prod)
											// 		}
											// 	})
											// })

											let newRows = calculateSuggestionsRow([item])

											addSuggestions(newRows)
										}}
									>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
											<path d="M2.5 7C2.5 9.48528 4.51472 11.5 7 11.5C9.48528 11.5 11.5 9.48528 11.5 7C11.5 4.51472 9.48528 2.5 7 2.5C4.51472 2.5 2.5 4.51472 2.5 7ZM2.5 17C2.5 19.4853 4.51472 21.5 7 21.5C9.48528 21.5 11.5 19.4853 11.5 17C11.5 14.5147 9.48528 12.5 7 12.5C4.51472 12.5 2.5 14.5147 2.5 17ZM12.5 17C12.5 19.4853 14.5147 21.5 17 21.5C19.4853 21.5 21.5 19.4853 21.5 17C21.5 14.5147 19.4853 12.5 17 12.5C14.5147 12.5 12.5 14.5147 12.5 17ZM9.5 7C9.5 8.38071 8.38071 9.5 7 9.5C5.61929 9.5 4.5 8.38071 4.5 7C4.5 5.61929 5.61929 4.5 7 4.5C8.38071 4.5 9.5 5.61929 9.5 7ZM9.5 17C9.5 18.3807 8.38071 19.5 7 19.5C5.61929 19.5 4.5 18.3807 4.5 17C4.5 15.6193 5.61929 14.5 7 14.5C8.38071 14.5 9.5 15.6193 9.5 17ZM19.5 17C19.5 18.3807 18.3807 19.5 17 19.5C15.6193 19.5 14.5 18.3807 14.5 17C14.5 15.6193 15.6193 14.5 17 14.5C18.3807 14.5 19.5 15.6193 19.5 17ZM16 11V8H13V6H16V3H18V6H21V8H18V11H16Z"></path>
										</svg>
									</div>
								</div>
							</Tooltip>
							<Tooltip label="Controlla stock" placement="top">
								<div className="zone-product-action">
									<div
										className={`icon-hover ${item.product ? '' : 'disabled'}`}
										onClick={() => {
											if (item.product) {
												axios
													.get(
														`${process.env.REACT_APP_WAREHOUSE_URL}/api/objects/stock/${
															item?.variant ? item.variant.cod : item?.product?.cod
														}`
													)
													.then((res) => {
														// console.log(res)
														if (res.data) {
															toast.success(
																`Quantità del prodotto a magazzino: ${res.data.stock}, riservata: ${res.data.reserved_stock}`
															)
														} else {
															toast.info(`Quantità del prodotto a magazzino non visualizzabile`)
														}
													})
													.catch((e) => {
														// console.log(e)
														toast.error(`Errore nel cercare quantità prodotto`)
													})
											}
										}}
									>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
											<path d="M3 10H2V4.00293C2 3.44903 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.43788 22 4.00293V10H21V20.0015C21 20.553 20.5551 21 20.0066 21H3.9934C3.44476 21 3 20.5525 3 20.0015V10ZM19 10H5V19H19V10ZM4 5V8H20V5H4ZM9 12H15V14H9V12Z"></path>
										</svg>
									</div>
								</div>
							</Tooltip>
							<Tooltip label="Rimuovi prodotto collegato" placement="top">
								<div className="zone-product-action">
									<div
										className={`icon-hover ${item.product ? '' : 'disabled'}`}
										onClick={() => {
											// copyItem(i)
											if (item.product) {
												handleItemInput(i, 'product', undefined)
											}
										}}
									>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
											<path fill="none" d="M0 0h24v24H0z" />
											<path d="M12.651 14.065L11.605 20H9.574l1.35-7.661-7.41-7.41L4.93 3.515 20.485 19.07l-1.414 1.414-6.42-6.42zm-.878-6.535l.27-1.53h-1.8l-2-2H20v2h-5.927L13.5 9.257 11.773 7.53z" />
										</svg>
									</div>
								</div>
							</Tooltip>
							<Tooltip label="Copia riga" placement="top">
								<div className="zone-product-action">
									<div
										className={`icon-hover`}
										onClick={() => {
											copyItem(i)
										}}
									>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
											<path fill="none" d="M0 0h24v24H0z" />
											<path d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7zM5.003 8L5 20h10V8H5.003zM9 6h8v10h2V4H9v2z" />
										</svg>
									</div>
								</div>
							</Tooltip>
							<Tooltip label="Forza lunghezza" placement="top">
								<div className="zone-product-action">
									<div
										className={`icon-hover ${item.showLength ? 'active' : ''}`}
										onClick={() => {
											// deleteItem(i)
											handleItemInput(i, 'showLength', !item.showLength)
										}}
									>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
											<path fill="none" d="M0 0h24v24H0z" />
											<path d="M7 17h10v-2.5l3.5 3.5-3.5 3.5V19H7v2.5L3.5 18 7 14.5V17zm6-11v9h-2V6H5V4h14v2h-6z" />
										</svg>
									</div>
								</div>
							</Tooltip>
							<Tooltip label="Item descrittivo (nome lungo)" placement="top">
								<div className="zone-product-action">
									<div
										className={`icon-hover ${item.type === 'descriptiveItem' ? 'active' : ''}`}
										onClick={() => {
											// handleItemInput(i, 'type', item.type === 'descriptiveItem' ? 'normalItem' : 'descriptiveItem')
											// console.log(item.type, item.type === 'descriptiveItem' ? 'normalItem' : 'descriptiveItem')
											if (item.type === 'descriptiveItem') {
												setItem(i, { ...item, name: item.namePlainText ? item.namePlainText : item.name, type: 'normalItem' })
											} else {
												setItem(i, {
													...item,
													namePlainText: item.name,
													type: 'descriptiveItem',
													length: undefined,
													info: undefined,
													infoPlainText: undefined,
												})
											}
										}}
									>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
											<path fill="none" d="M0 0h24v24H0z" />
											<path d="M15.246 14H8.754l-1.6 4H5l6-15h2l6 15h-2.154l-1.6-4zm-.8-2L12 5.885 9.554 12h4.892zM3 20h18v2H3v-2z" />
										</svg>
									</div>
								</div>
							</Tooltip>
							<Tooltip label="Rimuovi riga" placement="top" delayTime={0}>
								<div className="zone-product-action">
									<div
										className="icon-hover"
										onClick={() => {
											deleteItem(i)
										}}
									>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
											<path fill="none" d="M0 0h24v24H0z" />
											<path d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z" />
										</svg>
									</div>
								</div>
							</Tooltip>
						</FloatingDelayGroup>
					</div>
				</div>
			</div>
			{item.product && item.product.category === 'profiles' && (
				<>
					<div className="zone-product-entry small" style={{ alignSelf: item && item.product ? 'start' : 'center' }}>
						{' '}
					</div>
					<div className="zone-product-entry large" style={{ alignSelf: item && item.product ? 'start' : 'center', marginTop: '12px' }}>
						<Select
							value={covers.find((cover) => cover.value === item.profileCover) || { value: '', label: 'Nessuna copertura' }}
							options={covers}
							placeholder="Copertura..."
							onChange={(value) => {
								handleItemInput(i, 'profileCover', value.value)
							}}
						/>
					</div>
					<div className="zone-product-entry grow"></div>
				</>
			)}
		</div>
	)
}
