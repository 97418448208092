import create from 'zustand'
import { persist } from 'zustand/middleware'
import axios from 'axios'
import { toast } from 'react-toastify'

import IDBStorage from '../IDBStorage'
import useUserStore from './user'

import checkTokenError from '../checkTokenError'

let serverUrl = process.env.REACT_APP_SERVER_URL
//TODO: Sync local document with db one
const initialRegistersStore = {
	registers: [],
}

// Store for user Document, possible TODO: load document info from server??
const useRegistersStore = create(
	persist(
		(set, get) => ({
			...initialRegistersStore,
			clearStore: () => set({ ...initialRegistersStore }),
			fetchRegisters: () => {
				const token = useUserStore.getState().token

				return axios
					.get(`${serverUrl}/api/registers/user`, {
						headers: {
							authorization: token,
						},
					})
					.then((res) => {
						set({ registers: res && res.data })
						return 'success'
					})
					.catch((error) => {
						console.error(error)

						let message = checkTokenError(error)
						if (!message) message = 'Errore caricando utenti'

						toast.error({
							render: message,
							type: 'error',
							autoClose: 5000,
							isLoading: false,
							closeOnClick: true,
							pauseOnHover: true,
						})

						set({ registers: [] })
						// throw error
					})
			},
			getRegister: (id) => {
				const token = useUserStore.getState().token

				return axios
					.get(`${serverUrl}/api/registers/user/` + id, {
						headers: {
							authorization: token,
						},
					})
					.then((res) => {
						if (res.data) {
							return res.data
						}

						return null
					})
					.catch((error) => {
						console.error(error)
						throw error
					})
			},
			registerRegister: (register) => {
				const token = useUserStore.getState().token
				let data = get()

				return axios
					.post(`${serverUrl}/api/registers/user`, register, {
						headers: {
							authorization: token,
						},
					})
					.then((res) => {
						if (res.data) {
							const registers = Array.from(data.registers || [])
							registers.push(res.data)
							set({ registers: registers })
							return 'Success'
						}
					})
					.catch((error) => {
						console.error(error)
						throw error
					})
			},
			editRegister: (register) => {
				const token = useUserStore.getState().token
				let data = get()

				return axios
					.put(`${serverUrl}/api/registers/user/` + register._id, register, {
						headers: {
							authorization: token,
						},
					})
					.then((res) => {
						if (res.data) {
							const registers = Array.from(data.registers || [])
							// registers.push(res.data)
							const index = registers.findIndex((register) => register._id === res.data._id)
							if (index !== -1) {
								registers[index] = res.data
								set({ registers: registers })
							}
							return 'Success'
						}
					})
					.catch((error) => {
						console.error(error)
						throw error
					})
			},
			deleteRegister: (id) => {
				const token = useUserStore.getState().token
				let data = get()

				return axios
					.delete(`${serverUrl}/api/registers/user/` + id, {
						headers: {
							authorization: token,
						},
					})
					.then((res) => {
						if (res.data) {
							const registers = Array.from(data.registers || [])
							// registers.push(res.data)
							const index = registers.findIndex((register) => register._id === res.data._id)
							if (index !== -1) {
								registers.splice(index, 1)
								set({ registers: registers })
							}
							return 'Success'
						}
					})
					.catch((error) => {
						console.error(error)
						throw error
					})
			},
			deleteManyRegister: (ids) => {
				const token = useUserStore.getState().token
				let data = get()

				return axios
					.delete(`${serverUrl}/api/registers/user`, {
						headers: {
							authorization: token,
						},
						data: {
							ids: ids,
						},
					})
					.then((res) => {
						if (res.data) {
							const registers = Array.from(data.registers || [])
							ids.forEach((id) => {
								// registers.push(res.data)
								const index = registers.findIndex((register) => register._id === id)
								if (index !== -1) {
									registers.splice(index, 1)
								}
							})
							set({ registers: registers })
							return 'Success'
						}
					})
					.catch((error) => {
						console.error(error)
						throw error
					})
			},
			_hasHydrated: false,
		}),
		{
			name: 'registers-storage',
			getStorage: () => IDBStorage,
			onRehydrateStorage: () => () => {
				useRegistersStore.setState({ _hasHydrated: true })
			},
		}
	)
)

export default useRegistersStore
