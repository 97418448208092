import create from 'zustand'
import { persist } from 'zustand/middleware'
import { toast } from 'react-toastify'
import { cloneDeep } from 'lodash'

import useUserStore from './user'
import useDocumentStore from './document'
import productsStore from './products'
import useConfigurationStore from './configurations'

import axios from 'axios'

let serverUrl = process.env.REACT_APP_SERVER_URL

//TODO: Sync local document with db one
const initialVersionStore = {
	versions: [],
	selectedVersion: null,
	versionNumber: null,
	selectedVersionIndex: -1,
}

/*
	add:
	save "default" (in case you want to work on the base quote and not in a version)

*/

function parseVersion(obj, copy) {
	try {
		const importDoc = typeof obj.version === 'object' ? obj.version : JSON.parse(obj.version)

		const document = importDoc.document || {}
		if (!copy) {
			// document.id = importDoc.quoteIdentifier || obj._id
			// document.quoteIdentifier = importDoc.quoteIdentifier ? true : false
			document.id = obj._id
			document.quoteIdentifier = false
			document.identifier = obj.identifier
			document.userGroupId = importDoc.userGroup_id
			document.updatedAt = importDoc.updatedAt
		} else {
			document.id = null
			document.quoteIdentifier = null
			document.identifier = null
			document.userGroupId = null
			document.updatedAt = null
		}
		importDoc.document = document
		return importDoc
	} catch (e) {
		console.error(e)
	}
}

// Store for user Document, possible TODO: load document info from server??
const useVersionStore = create(
	persist(
		(set, get) => ({
			...cloneDeep(initialVersionStore),
			exportVersion: () => {
				const version = get().selectedVersion
				// document.id = null
				if (version) {
					const exportObj = {
						document: version.version,
						verifyIfIsCorrentKey: 'VerifyKeyQuote',
						usedPriceList: productsStore.getState().selectedPriceList,
					}
					return exportObj
				}

				return {}
			},
			importVersion: (version, copy = false) => {
				let importObj = typeof version === 'object' ? version : JSON.parse(version)

				// console.log(quote)
				try {
					const versionObj = parseVersion(version, copy)
					if (versionObj.verifyIfIsCorrentKey === 'VerifyKeyQuote') {
						// const register = versionImport.register
						// const updatedAt = versionImport.updatedAt
						// const quoteDate = versionImport.quoteDate

						set({ selectedVersion: { ...importObj, ...versionObj } })
						if (versionObj.usedPriceList) {
							let newPriceLists = {}
							let priceLists = productsStore.getState().priceLists
							let newSelectedPriceList = Array.isArray(priceLists) && priceLists.find((pl) => pl.slug === versionObj.usedPriceList.slug)
							if (newSelectedPriceList) {
								newPriceLists.selectedPriceList = newSelectedPriceList
								productsStore.setState(newPriceLists)
							}
						}
						if (versionObj.attributeNames) useConfigurationStore.getState().setConfiguration('attributeNames', versionObj.attributeNames)
					} else {
					}
				} catch (e) {
					console.error(e)
				}
			},
			fetchVersions: (fetchId, fetchIsId) => {
				const token = useUserStore.getState().token
				const savedVersions = get().versions
				let id = fetchId ? fetchId : useDocumentStore.getState().document.id
				let isId = fetchIsId ? fetchIsId : !useDocumentStore.getState().document.quoteIdentifier

				if (id) {
					return axios
						.get(`${serverUrl}/api/versions/user/${id}/${isId ? 'id' : 'identifier'}`, {
							headers: {
								authorization: token,
							},
						})
						.then((res) => {
							const versions = res && res.data
							let changed = false
							if (savedVersions.length !== versions.length) {
								changed = true
							}

							versions.forEach((version) => {
								let savedVersion = savedVersions.find((savedV) => savedV._id === version._id)

								if (!savedVersion) {
									changed = true
								} else {
									if (savedVersion.updatedAt !== version.updatedAt) {
										changed = true
									}
								}
							})

							if (changed) {
								set({ versions: versions })
							}
							// set({versions: versions})
						})
				} else {
					set({ versions: [] })
				}
			},
			selectVersion: (index) => {
				useDocumentStore.getState().setVersion()
				let versions = get().versions

				if (versions[index]) {
					set({ selectedVersion: versions[index], versionNumber: versions[index].versionNumber, selectedVersionIndex: index })

					// let importObj = typeof versions[index] === 'object' ? versions[index] : JSON.parse(versions[index])

					// console.log(quote)
					// try {
					// 	const versionObj = parseVersion(versions[index], false)

					// 	console.log(versionObj)

					// 	// const register = versionImport.register
					// 	// const updatedAt = versionImport.updatedAt
					// 	// const quoteDate = versionImport.quoteDate

					// 	// set({
					// 	// 	selectedVersion: { ...importObj, document: versionObj },
					// 	// 	selectedVersionIndex: index,
					// 	// 	versionNumber: versions[index].versionNumber,
					// 	// })
					// 	productsStore.setState({ selectedPriceList: versionObj.usedPriceList })
					// } catch (e) {
					// 	console.error(e)
					// }
				}
			},
			removeVersion: () => {
				set({ selectedVersion: null, versionNumber: null, selectedVersionIndex: -1 })
			},
			deleteVersion: (deleteId) => {
				const token = useUserStore.getState().token
				const id = useDocumentStore.getState().document.id
				const type = useDocumentStore.getState().document.quoteIdentifier
				const selectedVersion = get().selectedVersion

				let documentStore = useDocumentStore.getState()

				documentStore.removeVersion()

				return axios
					.delete(`${serverUrl}/api/versions/user/${id}/${type ? 'identifier' : 'id'}/${selectedVersion._id}/id`, {
						headers: {
							authorization: token,
						},
					})
					.then((res) => {
						let newVersions = get().versions
						if (!Array.isArray(newVersions)) {
							newVersions = []
						}

						let removedIndex = newVersions.findIndex((version) => version._id === res.data && res.data._id)

						if (removedIndex !== -1) {
							newVersions.splice(removedIndex, 1)
						}

						set({ versions: [...newVersions], selectedVersion: null, selectedVersionIndex: -1, versionNumber: null })
					})
			},
			updateVersion: () => {
				const token = useUserStore.getState().token
				const store = useDocumentStore.getState()
				const referer = store.document.referer
				const quoteDate = store.document.quoteDate
				// const documnetName = store.document.name
				const register = store.register
				const quote = JSON.stringify(store.exportDocument())
				const updateData = { quote: quote, referer: referer }
				const id = store.document.id
				const type = store.document.quoteIdentifier
				// let note = store.document.note
				let versionId = store.versionId
				let versionType = true

				// console.log(store)

				if (register) {
					updateData.register = register
				}

				if (quoteDate !== undefined) {
					updateData.quoteDate = quoteDate
				}

				updateData.quoteIdentifier = undefined

				return axios
					.put(`${serverUrl}/api/versions/user/${id}/${type ? 'identifier' : 'id'}/${versionId}/${versionType ? 'id' : 'version'}`, updateData, {
						headers: {
							authorization: token,
						},
					})
					.then((res) => {
						let newVersions = get().versions
						if (!Array.isArray(newVersions)) {
							newVersions = []
						}

						let newVersionsIndex = newVersions.findIndex((ver) => ver._id === (res.data && res.data._id))

						if (newVersionsIndex !== -1) {
							newVersions[newVersionsIndex] = res.data
						}

						set({
							versions: [...newVersions],
							selectedVersion: newVersions[newVersions.length - 1],
							selectedVersionIndex: newVersions.length - 1,
							versionNumber: res.versionNumber,
						})

						return true
					})
			},
			newVersion: (name) => {
				const token = useUserStore.getState().token
				const id = useDocumentStore.getState().document.id
				const type = useDocumentStore.getState().document.quoteIdentifier
				let note = name

				if (!note) {
					note = window.prompt('Inserisci Nome per la versione:', '')
				}

				if (note) {
					return axios
						.post(
							`${serverUrl}/api/versions/user/${id}/${type ? 'identifier' : 'id'}`,
							{ note: note },
							{
								headers: {
									authorization: token,
								},
							}
						)
						.then((res) => {
							let newVersions = get().versions
							if (!Array.isArray(newVersions)) {
								newVersions = []
							}

							newVersions.push(res.data)
							set({
								versions: [...newVersions],
								selectedVersion: newVersions[newVersions.length - 1],
								selectedVersionIndex: newVersions.length - 1,
								versionNumber: res.versionNumber,
							})

							useDocumentStore.getState().setVersion(res.data)
						})
						.catch((e) => {
							// console.dir(e)

							if (e && e.response && e.response.data && e.response.data.message) {
								if (e.response.data.message === 'Missing quote id') {
									toast.error('Errore nel creare la versione, prima salvare il preventivo', {
										autoClose: 5000,
										isLoading: false,
										closeOnClick: true,
										pauseOnHover: true,
									})
								} else {
									toast.error('Errore nel creare la versione', {
										autoClose: 5000,
										isLoading: false,
										closeOnClick: true,
										pauseOnHover: true,
									})
								}
							} else {
								toast.error('Errore nel creare la versione', {
									autoClose: 5000,
									isLoading: false,
									closeOnClick: true,
									pauseOnHover: true,
								})
							}
						})
				}
			},
		}),
		{
			name: 'version-storage', // unique name
			// storage: typeof window !== 'undefined' ? window.localStorage : dummyStorageApi,
			// ...
			// partialize: (state) => Object.fromEntries(Object.entries(state).filter(([key, value]) => typeof value !== 'function')),
		}
	)
)

// const functions =

export default useVersionStore
