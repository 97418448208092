import React, { useState, useRef, useEffect, useMemo } from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import { toast } from 'react-toastify'
import Select from 'react-select'

import RemoveElement from '../RemoveElement'
import CopyElement from '../graphic/CopyElement'
import DragElement from '../graphic/DragElement'
import RefreshPrices from '../graphic/RefreshPrices'
import ZoneItem from './ZoneItem'
import ZoneNote from './ZoneNote'
import Configurator from '../extra/Configurator'
import ConfiguratorOld from '../extra/Configurator_old'

import parseCustomProduct, { parseCustomProductNew } from '../../lib/utils/parseCustomProduct'
// import getDiscount from '../../lib/getDiscount'
import getAttribute from '../../lib/getAttribute'
import retrieveSavedAttributes from '../../lib/retrieveSavedAttribute'
// import findVariant from '../../lib/utils/profilesVariants'
import calculateItemPrice, { calculateProductPrice } from '../../lib/utils/calculateItemPrice'

import useProductsStore from '../../lib/stores/products'
import useDocumentStore from '../../lib/stores/document'
import useModalStore from '../../lib/stores/modal'
import useConfigurationStore from '../../lib/stores/configurations'
import useUserStore from '../../lib/stores/user'

import '../../styles/components/Zone.scss'
import calculateItemLength from '../../lib/utils/calculateItemLength'
import { getProductCovers } from '../../lib/utils/profilesVariants'
import NewConfigurator from '../extra/NewConfigurator'
import SuggestionsModal from '../graphic/SuggestionsModal'
import useSuggestionsStore from '../../lib/stores/suggestions'

// const suggestions = [
// 	{
// 		product_id: '5EEC',
// 		slug: 'tappi',
// 		tags: ['all'],
// 		items: [
// 			{ cod: 'FEEE', quantity: 1 },
// 			{ cod: 'FEEF', quantity: 1 },
// 		],
// 		extra: 'profilo 23x20, testata chiusa, testata forata',
// 	},
// 	{
// 		product_id: '7CCE',
// 		slug: 'tappi',
// 		tags: ['all'],
// 		items: [{ cod: '11A63', quantity: 1 }],
// 		extra: 'profilo 26x34, testata di chiusura',
// 	},
// 	{
// 		product_id: '7CD8',
// 		slug: 'tappi',
// 		tags: ['all'],
// 		items: [{ cod: '11A6D', quantity: 1 }],
// 		extra: 'profilo 30x90, testata di chiusura con viti',
// 	},
// 	{
// 		product_id: '7CEC',
// 		slug: 'tappi',
// 		tags: ['all'],
// 		items: [{ cod: '11A81', quantity: 1 }],
// 		extra: 'profilo spigolo, testata di chiusura',
// 	},
// 	{
// 		product_id: '7CF6',
// 		slug: 'tappi',
// 		tags: ['all'],
// 		items: [{ cod: '11A8B', quantity: 1 }],
// 		extra: 'profilo taglio piccolo di luce, testata di chiusura',
// 	},
// 	{
// 		product_id: '7D00',
// 		slug: 'tappi',
// 		tags: ['all', 'emission_single'],
// 		items: [
// 			{ cod: '11A95', quantity: 1, tags: ['emission_single'] },
// 			{ cod: '11A96', quantity: 1, tags: ['emission_single'] },
// 			{ cod: '11A97', quantity: 1, tags: ['emission_double'] },
// 			{ cod: '11A98', quantity: 1, tags: ['emission_double'] },
// 		],
// 		extra: 'profilo parete ala, testata destra luce singola, testata sinistra luce singola,  testata destra luce doppia, testata destra luce doppia',
// 	},
// 	{
// 		product_id: '7D00',
// 		slug: 'accessories',
// 		tags: ['accessories', 'extra'],
// 		items: [
// 			{ cod: '7D01', quantity: 1 },
// 			{ cod: '7D02', quantity: 1 },
// 		],
// 		extra: 'profilo parete ala, base, cover a scatto',
// 	},
// 	{
// 		product_id: '7CB0',
// 		slug: 'tappi',
// 		tags: ['all'],
// 		items: [{ cod: '11A3B', quantity: 1 }],
// 		extra: 'profilo astro 22x50, testata di chiusura',
// 	},
// 	{
// 		product_id: '7CB0',
// 		slug: 'tappi',
// 		tags: ['all'],
// 		items: [{ cod: '11A3B', quantity: 1 }],
// 		extra: 'profilo astro 22x50, testata di chiusura',
// 	},
// ]

export default function Zone({ zone, elementIndex, provided }) {
	const { addModal, removeModal } = useModalStore((state) => ({ addModal: state.addModal, removeModal: state.removeModal }))
	const [selectedInput, setSelectedInput] = useState(-1)

	const { selectedPriceList, products } = useProductsStore((state) => ({
		selectedPriceList: state.selectedPriceList,
		products: state.products,
	}))
	const { calculateSuggestionsRow } = useSuggestionsStore((state) => ({ calculateSuggestionsRow: state.calculateSuggestionsRows }))
	const { setElement } = useDocumentStore((state) => ({ setElement: state.setElement }))
	const configurations = useConfigurationStore((state) => state.configurations)
	const titleRef = useRef()

	const { token } = useUserStore((state) => ({ token: state.token, user: state.user }))

	// Get title Ref and select it (when page loads or when a new zone is added, cursor goes automatically into the last / new zone)
	useEffect(() => {
		if (titleRef && titleRef.current) {
			titleRef.current.focus()
			titleRef.current.select()
		}
	}, [])

	// console.log(zone, products)

	// Calculate power Infos, + voltage infos for power supply
	const [totalWatt, totalWattCustom, voltageString, voltageWatts] = useMemo(() => {
		let watt = 0
		let wattCustom = 0

		let voltageWatts = {}

		if (Array.isArray(zone.items)) {
			for (let i = 0; i < zone.items.length; i++) {
				let toAddWatt = 0.0
				let toAddWattCustom = 0.0

				if (zone.items[i].type === 'customProduct') {
					if (zone.items[i].productInfo && zone.items[i].productInfo.powerInfo)
						toAddWattCustom = parseFloat(zone.items[i].productInfo.powerInfo.watt) || 0
				}

				// console.log(zone.items[i].product)
				if (zone.items[i].product && zone.items[i].product.category === 'led_strips') {
					let voltage = zone.items[i]?.product?.attributes?.volt

					toAddWatt =
						parseFloat(
							zone.items[i].product &&
								(zone.items[i].product.watt_tot
									? zone.items[i].product.watt_tot
									: zone.items[i].product.attributes && zone.items[i].product.attributes.watt_meter * 5)
						) || 0

					if (voltage) {
						if (!voltageWatts[voltage]) voltageWatts[voltage] = 0

						voltageWatts[voltage] += toAddWatt * parseInt(zone.items[i].quantity)
					}
				}
				toAddWatt = toAddWatt * parseInt(zone.items[i].quantity)
				toAddWattCustom = toAddWattCustom * parseInt(zone.items[i].quantity)

				watt += toAddWatt
				wattCustom += toAddWattCustom
			}
		}

		return [Math.ceil(watt), Math.ceil(wattCustom), Object.keys(voltageWatts).join(','), voltageWatts]
	}, [zone])

	// Calculate power supplies based on voltage && watt infos
	let [powerSupplies] = useMemo(() => {
		let filteredProducts = []

		if (Array.isArray(products)) {
			let voltages = voltageString.split(',').map((volt) => parseInt(volt))
			filteredProducts = products.filter((prod) => prod.product_category_slug === 'power_supplies' && voltages.includes(prod.attributes?.volt))

			filteredProducts = filteredProducts.sort((a, b) => (b.search_weight ? b.search_weight : 0) - (a.search_weight ? a.search_weight : 0)).slice(0, 20)
		}
		return [filteredProducts]

		// eslint-disable-next-line
	}, [totalWatt, voltageString, products])

	// What name to display for product when selecting it
	function productLabel(product) {
		let finishing = product.finishing
		let led_ct = product.led_ct
		let watt_tot = product.watt_tot
		let pcb_ip = product.pcb_ip
		let name = getAttribute(product, 'name')

		switch (product.category) {
			case 'profile_accessories':
				// ['finishing', 'dimensions']

				return `${name}${finishing ? ' - ' + finishing : ''}`
			case 'profiles':
				// ['finishing', 'dimensions']

				return `${name}${finishing ? ' - ' + finishing : ''}`
			case 'led_strips':
				// ['led_ct', 'pcb_ip', 'watt_tot', 'watt_meter']

				return `${name}${led_ct ? ' - ' + led_ct : ''}${watt_tot ? ' - ' + watt_tot : ''}${pcb_ip ? ' - ' + pcb_ip : ''}`
			case 'power_supplies':
				// ['ip', 'watt', 'volt']

				return `${name}`
			default:
				return name
		}
	}

	function addSuggestions(newRows) {
		if (newRows.length <= 0) {
			toast.info(`Nessun prodotto suggerito trovato`)
		} else {
			const items = [...zone.items]
			for (let i = 0; i < newRows.length; i++) {
				let product = newRows[i]
				let newItem = { product: product, quantity: newRows[i].quantity ? newRows[i].quantity : 1 }

				newItem.name = productLabel(product)

				// Set product && remove variant infos

				// If profile, check for variants
				if (product.category === 'profiles') {
					let covers = getProductCovers(product)

					if (Array.isArray(covers) && covers.length > 0) {
						let opal = covers.find((cover) => /opal/gimu.test(cover.label))

						if (opal) {
							newItem.profileCover = opal.value
						} else {
							newItem.profileCover = covers[0].value
						}
					}

					calculateItemPrice(newItem, productLabel, selectedPriceList)
				} else {
					newItem = calculateProductPrice(product, newItem, null, false, selectedPriceList)
				}

				items.push({ id: uuidv4(), quantity: 1, name: '', info: '', price: 0.0, length: 1, discount: '', ...newItem })
			}

			handleInput('items', items)
		}
	}

	// set Zone value (title, items)
	function handleInput(key, value) {
		if (typeof setElement === 'function') {
			const element = { ...zone }
			element[key] = value
			setElement(element, elementIndex)
		} else {
			console.error('document Set Element not defined')
		}
	}

	function setItem(i, item) {
		const items = [...zone.items]

		items[i] = item
		handleInput('items', items)
	}

	function addItem(item) {
		const items = [...zone.items]
		items.push({ id: uuidv4(), quantity: 1, name: '', info: '', price: 0.0, length: 1, discount: '', ...item })

		handleInput('items', items)
	}

	function deleteItem(index) {
		const items = [...zone.items]
		if (items.length > 1) {
			items.splice(index, 1)

			handleInput('items', items)
		}
	}

	function copyItem(i, item) {
		const items = [...zone.items]
		let temp = { ...items[i] }
		temp.id = uuidv4()
		items.splice(i + 1, 0, temp)

		handleInput('items', items)
	}

	// handle input on item (and update price if needed)
	function handleItemInput(index, keys, values) {
		const items = [...zone.items]

		if (items[index]) {
			let keysLength = Array.isArray(keys) ? keys.length : 1
			for (let i = 0; i < keysLength; i++) {
				let key = Array.isArray(keys) ? keys[i] : keys
				let value = Array.isArray(keys) ? values[i] : values

				items[index][key] = value

				switch (key) {
					case 'price':
						items[index].hasCustomPrice = true
					// falls through
					case 'discount':
					case 'quantity':
					case 'length':
					case 'product':
					case 'profileCover':
						items[index] = calculateItemPrice(items[index], productLabel, selectedPriceList)

						break
					default:
						break
				}
			}
			handleInput('items', items)
		}
	}

	// Recalculate item prices based on priceList
	function refreshPrices() {
		let items = [...zone.items]

		items = items.map((item) => {
			// console.log(item)
			if (item.product) {
				if (item.variant) {
					let [length] = calculateItemLength(item)

					let profileLength = Math.ceil(length)

					let variant = products.find((prod) => prod.cod === item.variant.cod)

					item = calculateProductPrice(
						variant,
						item,
						item.isCmLength ? profileLength : null,
						item.variant && !item.isCmLength ? true : false,
						selectedPriceList
					)
				} else {
					let product = products.find((prod) => prod.cod === item.product.cod)
					item = calculateProductPrice(product, item, null, false, selectedPriceList)
				}
				item.hasCustomPrice = false
			}

			return item
		})
		handleInput('items', items)
	}

	// handle select item from search
	function selectItem(index, product) {
		const items = [...zone.items]
		if (index > items.length || index < 0) {
			console.error('wrong index for select item')
			return
		}
		if (!product) {
			console.error('no product selected on selectItem')
			return
		}

		items[index].name = productLabel(product)

		// Set product && remove variant infos
		items[index].product = product
		delete items[index].variant
		delete items[index].isCmLength
		delete items[index].profileCover

		// If profile, check for variants
		if (product.category === 'profiles') {
			let covers = getProductCovers(product)

			if (Array.isArray(covers) && covers.length > 0) {
				let opal = covers.find((cover) => /opal/gimu.test(cover.label))

				if (opal) {
					items[index].profileCover = opal.value
				} else {
					items[index].profileCover = covers[0].value
				}
			}

			calculateItemPrice(items[index], productLabel, selectedPriceList)
		} else {
			items[index] = calculateProductPrice(product, items[index], null, false, selectedPriceList)
		}

		handleInput('items', items)
	}

	// Calculate zone's total price
	let pricesSum = 0
	let pricesTotal = 0

	if (Array.isArray(zone.items)) {
		zone.items.forEach((item) => {
			if (!isNaN(item.price)) {
				let [length] = calculateItemLength(item)

				length = Math.ceil(length / 50) / 2

				pricesSum -= -item.price * item.quantity * (item.variant ? 1 : length)
			}
			if (!isNaN(item.total)) {
				pricesTotal -= -item.total
			}
		})
	}

	if (!zone) {
		return null
	}

	return (
		<div className="element zone">
			<input
				ref={titleRef}
				className="zone-title element-title"
				value={zone.title}
				placeholder="Zona..."
				onChange={(e) => {
					e.preventDefault()
					handleInput('title', e.target.value)
				}}
			/>
			<div className="zone-content shadow">
				<div className="zone-products-list">
					<div>
						<div className="zone-product-header">
							<label className="zone-product-entry small">Qty</label>
							<label className="zone-product-entry large">Prodotto / lunghezza</label>
							<label className="zone-product-entry medium-large">Info</label>
							<label className="zone-product-entry medium">Prezzo</label>
							<label className="zone-product-entry medium">Sconto</label>
							<label className="zone-product-entry medium">Prezzo Cad.</label>
							<label className="zone-product-entry medium">Totale</label>
							{/* <label className="zone-product-entry drag">Drag</label> */}
						</div>
					</div>

					{provided ? (
						<Droppable droppableId={`droppableItemsElem_${elementIndex}`} index={elementIndex} type="droppableZoneItem">
							{(provided, snapshot) => (
								<div {...provided.droppableProps} ref={provided.innerRef}>
									{Array.isArray(zone.items) &&
										zone.items.map((item, i, arr) => {
											return (
												<Draggable draggableId={item.id} index={i} key={item.id}>
													{(provided, snapshot) =>
														item.type === 'notesItem' ? (
															<ZoneNote
																item={item}
																i={i}
																items={arr}
																provided={provided}
																handleItemInput={handleItemInput}
																setItem={setItem}
																copyItem={copyItem}
																addItem={addItem}
																deleteItem={deleteItem}
																setSelectedInput={setSelectedInput}
															/>
														) : (
															<ZoneItem
																item={item}
																i={i}
																items={arr}
																provided={provided}
																handleItemInput={handleItemInput}
																setItem={setItem}
																copyItem={copyItem}
																addItem={addItem}
																deleteItem={deleteItem}
																selectItem={selectItem}
																selectedInput={selectedInput}
																setSelectedInput={setSelectedInput}
																productLabel={productLabel}
																autoselect={i !== 0}
																parseCustomProduct={parseCustomProduct}
																addSuggestions={addSuggestions}
															/>
														)
													}
												</Draggable>
											)
										})}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					) : (
						<div>
							{Array.isArray(zone.items) &&
								zone.items.map((item, i, arr) => {
									return item.type === 'notesItem' ? (
										<ZoneNote
											item={item}
											i={i}
											items={arr}
											provided={provided}
											handleItemInput={handleItemInput}
											setItem={setItem}
											copyItem={copyItem}
											addItem={addItem}
											deleteItem={deleteItem}
											setSelectedInput={setSelectedInput}
										/>
									) : (
										<ZoneItem
											key={item.id}
											item={item}
											i={i}
											items={arr}
											handleItemInput={handleItemInput}
											setItem={setItem}
											copyItem={copyItem}
											addItem={addItem}
											deleteItem={deleteItem}
											selectItem={selectItem}
											selectedInput={selectedInput}
											setSelectedInput={setSelectedInput}
											productLabel={productLabel}
											autoselect={i !== 0}
											parseCustomProduct={parseCustomProduct}
											addSuggestions={addSuggestions}
										/>
									)
								})}
						</div>
					)}

					<div className="zone-product-footer">
						<label className="zone-product-entry small">
							{/* {true && ( */}
							<div
								className="zone-add-product"
								onClick={() => {
									addItem()
								}}
							>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11H7v2h4v4h2v-4h4v-2h-4V7h-2v4z" />
								</svg>
							</div>
							<div
								className="zone-add-product"
								onClick={() => {
									addItem({ type: 'notesItem' })
								}}
							>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
									<path d="M4 1V4H1V6H4V9H6V6H9V4H6V1H4ZM3 20.0066V11H5V19H13V14C13 13.45 13.45 13 14 13L19 12.999V5H11V3H20.0066C20.5552 3 21 3.45576 21 4.00247V15L15 20.996L4.00221 21C3.4487 21 3 20.5551 3 20.0066ZM18.171 14.999L15 15V18.169L18.171 14.999Z"></path>
								</svg>
							</div>

							<div
								className="zone-add-product"
								onClick={() => {
									// let newRows = []
									// let suggestCodsList = []
									// zone.items.forEach((item) => {
									// 	if (item.product) {
									// 		//
									// 		let codParts = item.product.cod.split(/[.-]/g)
									// 		let familyCod = codParts[0]
									// 		let finishingCod = codParts[1]
									// 		// let suggestionObj = suggestions.find((suggItem) => suggItem.product_id === familyCod)
									// 		let suggestionObjArr = suggestions[familyCod]
									// 		if (Array.isArray(suggestionObjArr)) {
									// 			suggestionObjArr.forEach((suggestionObj) => {
									// 				if (suggestionObj) {
									// 					suggestCodsList.push({
									// 						quantity: item.quantity,
									// 						familyCod: familyCod,
									// 						finishingCod: finishingCod,
									// 						suggestionObj: suggestionObj,
									// 						items: suggestionObj.items,
									// 					})
									// 				}
									// 			})
									// 		}
									// 	}
									// })
									// if (suggestCodsList.length > 0) {
									// 	suggestCodsList = suggestCodsList.map((list) => {
									// 		let listRow = {
									// 			...list,
									// 			products: {},
									// 		}
									// 		products.forEach((product) => {
									// 			let item = list.items.find((it) => product.cod.startsWith(it.cod))
									// 			if (item) {
									// 				//
									// 				if (!Array.isArray(listRow.products[item.cod])) listRow.products[item.cod] = []
									// 				listRow.products[item.cod].push(product)
									// 			}
									// 		})
									// 		return listRow
									// 	})
									// }
									// // console.log(suggestCodsList)
									// suggestCodsList.forEach((list) => {
									// 	//
									// 	let productKeys = Object.keys(list.products)
									// 	productKeys.forEach((key) => {
									// 		// calculate correct suggestion (based on finishing)
									// 		if (list.products[key].length === 1) {
									// 			//
									// 			newRows.push(list.products[key][0])
									// 		} else {
									// 			let prod = calculateSuggestions(list.finishingCod, list.products[key])
									// 			if (prod) newRows.push(prod)
									// 		}
									// 	})
									// })
									let newRows = calculateSuggestionsRow(zone.items)

									// console.log(newRows)
									if (newRows.length <= 0) {
										toast.info(`Nessun prodotto suggerito trovato`)
									} else {
										addModal({
											modal: (
												<div style={{ width: '85vw', height: '85vh' }}>
													<SuggestionsModal
														importRows={newRows}
														confirmFunction={(toAddRows) => {
															//

															addSuggestions(toAddRows)
															removeModal('suggestionsModal')
														}}
														closeModal={() => {
															removeModal('suggestionsModal')
														}}
													/>
												</div>
											),
											id: 'suggestionsModal',
											background: true,
											replaceModal: true,
											disposableModal: true,
										})
									}
									//
									//
									//
									//
									// const items = [...zone.items]
									// for (let i = 0; i < newRows.length; i++) {
									// 	let product = newRows[i]
									// 	let newItem = { product: product, quantity: newRows[i].quantity ? newRows[i].quantity : 1 }
									// 	//
									// 	newItem.name = productLabel(product)
									// 	// Set product && remove variant infos
									// 	// If profile, check for variants
									// 	if (product.category === 'profiles') {
									// 		let covers = getProductCovers(product)
									// 		if (Array.isArray(covers) && covers.length > 0) {
									// 			let opal = covers.find((cover) => /opal/gimu.test(cover.label))
									// 			if (opal) {
									// 				newItem.profileCover = opal.value
									// 			} else {
									// 				newItem.profileCover = covers[0].value
									// 			}
									// 		}
									// 		calculateItemPrice(newItem, productLabel, selectedPriceList)
									// 	} else {
									// 		newItem = calculateProductPrice(product, newItem, null, false, selectedPriceList)
									// 	}
									// 	items.push({ id: uuidv4(), quantity: 1, name: '', info: '', price: 0.0, length: 1, discount: '', ...newItem })
									// }
									// handleInput('items', items)
								}}
							>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11H7v2h4v4h2v-4h4v-2h-4V7h-2v4z" />
								</svg>
							</div>
							{/* )} */}
						</label>
						<label className="zone-product-entry large">
							<div className="zone-actions-configurator">
								<div
									className="actions-configurator-option"
									onClick={() => {
										if (typeof addModal === 'function') {
											let savedAttribute = retrieveSavedAttributes()

											addModal({
												modal: (
													<div style={{ width: '85vw', height: '85vh' }}>
														{configurations.useOldConfigurator ? (
															<ConfiguratorOld
																configMode="strip"
																exportFunction={async ({ price, ...productData }) => {
																	let productInfo = parseCustomProduct(productData)

																	productInfo.type = 'customStrip'

																	let productRes = await axios.post(
																		`${process.env.REACT_APP_SERVER_URL}/api/configurator-products/`,
																		{
																			price: price,
																			...productInfo,
																		},
																		{
																			headers: {
																				authorization: token,
																			},
																		}
																	)

																	if (productRes && productRes.data) {
																		productInfo.id = productRes.data.id
																	}

																	addItem({
																		type: 'customProduct',
																		productInfo: productInfo,
																		price: price,
																		priceCad: price,
																		total: price,
																	})

																	removeModal('ConfiguratorModal')
																}}
																closeFunction={() => removeModal('ConfiguratorModal')}
																attributeInfo={savedAttribute}
																selectedPriceList={selectedPriceList}
															/>
														) : (
															<Configurator
																configMode="strip"
																exportFunction={async ({ price, ...productData }) => {
																	let productInfo = parseCustomProduct(productData)

																	productInfo.type = 'customStrip'

																	let productRes = await axios.post(
																		`${process.env.REACT_APP_SERVER_URL}/api/configurator-products/`,
																		{
																			price: price,
																			...productInfo,
																		},
																		{
																			headers: {
																				authorization: token,
																			},
																		}
																	)

																	if (productRes && productRes.data) {
																		productInfo.id = productRes.data.id
																	}

																	addItem({
																		type: 'customProduct',
																		productInfo: productInfo,
																		price: price,
																		priceCad: price,
																		total: price,
																	})

																	removeModal('ConfiguratorModal')
																}}
																closeFunction={() => removeModal('ConfiguratorModal')}
																attributeInfo={savedAttribute}
																selectedPriceList={selectedPriceList}
															/>
														)}
													</div>
												),
												id: 'ConfiguratorModal',
												background: true,
												replaceModal: true,
												disposableModal: true,
											})
										} else {
											console.log('addModal not funciton?', addModal)
										}
									}}
								>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ marginRight: '3px' }}>
										<path fill="none" d="M0 0h24v24H0z" />
										<path d="M17 19h2v-5h-9V5H5v2h2v2H5v2h3v2H5v2h2v2H5v2h2v-2h2v2h2v-3h2v3h2v-2h2v2zm-5-7h8a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v8z" />
									</svg>
									Strisce LED
								</div>
								<div
									className="actions-configurator-option"
									onClick={() => {
										if (typeof addModal === 'function') {
											let savedAttribute = retrieveSavedAttributes()

											addModal({
												modal: (
													<div style={{ width: '85vw', height: '85vh' }}>
														{configurations.useOldConfigurator ? (
															<ConfiguratorOld
																configMode="profile"
																exportFunction={async ({ price, ...productData }) => {
																	let productInfo = parseCustomProduct(productData)

																	productInfo.type = 'customProfile'

																	let productRes = await axios.post(
																		`${process.env.REACT_APP_SERVER_URL}/api/configurator-products/`,
																		{
																			price: price,
																			...productInfo,
																		},
																		{
																			headers: {
																				authorization: token,
																			},
																		}
																	)

																	if (productRes && productRes.data) {
																		productInfo.id = productRes.data.id
																	}

																	addItem({
																		type: 'customProduct',
																		productInfo: productInfo,
																		price: price,
																		priceCad: price,
																		total: price,
																	})

																	removeModal('ConfiguratorModal')
																}}
																closeFunction={() => removeModal('ConfiguratorModal')}
																attributeInfo={savedAttribute}
																selectedPriceList={selectedPriceList}
															/>
														) : (
															<Configurator
																configMode="profile"
																exportFunction={async ({ price, ...productData }) => {
																	let productInfo = parseCustomProduct(productData)

																	productInfo.type = 'customProfile'

																	let productRes = await axios.post(
																		`${process.env.REACT_APP_SERVER_URL}/api/configurator-products/`,
																		{
																			price: price,
																			...productInfo,
																		},
																		{
																			headers: {
																				authorization: token,
																			},
																		}
																	)

																	if (productRes && productRes.data) {
																		productInfo.id = productRes.data.id
																	}

																	addItem({
																		type: 'customProduct',
																		productInfo: productInfo,
																		price: price,
																		priceCad: price,
																		total: price,
																	})

																	removeModal('ConfiguratorModal')
																}}
																closeFunction={() => removeModal('ConfiguratorModal')}
																attributeInfo={savedAttribute}
																selectedPriceList={selectedPriceList}
															/>
														)}
													</div>
												),
												id: 'ConfiguratorModal',
												background: true,
												replaceModal: true,
												disposableModal: true,
											})
										} else {
											console.log('addModal not funciton?', addModal)
										}
									}}
								>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ marginRight: '3px' }}>
										<path fill="none" d="M0 0h24v24H0z" />
										<path d="M17 19h2v-5h-9V5H5v2h2v2H5v2h3v2H5v2h2v2H5v2h2v-2h2v2h2v-3h2v3h2v-2h2v2zm-5-7h8a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v8z" />
									</svg>
									Taglio Profili
								</div>
								<div
									className="actions-configurator-option"
									onClick={() => {
										if (typeof addModal === 'function') {
											let savedAttribute = retrieveSavedAttributes()
											addModal({
												modal: (
													<div style={{ width: '85vw', height: '85vh' }}>
														{configurations.useOldConfigurator ? (
															<ConfiguratorOld
																configMode="lamp"
																exportFunction={async ({ price, ...productData }) => {
																	let productInfo = parseCustomProduct(productData)

																	productInfo.type = 'customLamp'

																	//
																	let productRes = await axios.post(
																		`${process.env.REACT_APP_SERVER_URL}/api/configurator-products/`,
																		{
																			price: price,
																			...productInfo,
																		},
																		{
																			headers: {
																				authorization: token,
																			},
																		}
																	)

																	if (productRes && productRes.data) {
																		productInfo.id = productRes.data.id
																	}

																	addItem({
																		type: 'customProduct',
																		productInfo: productInfo,
																		price: price,
																		total: price,
																		priceCad: price,
																	})

																	removeModal('ConfiguratorModal')
																}}
																closeFunction={() => removeModal('ConfiguratorModal')}
																attributeInfo={savedAttribute}
																selectedPriceList={selectedPriceList}
															/>
														) : (
															<Configurator
																configMode="lamp"
																exportFunction={async ({ price, ...productData }) => {
																	let productInfo = parseCustomProduct(productData)

																	productInfo.type = 'customLamp'

																	//
																	let productRes = await axios.post(
																		`${process.env.REACT_APP_SERVER_URL}/api/configurator-products/`,
																		{
																			price: price,
																			...productInfo,
																		},
																		{
																			headers: {
																				authorization: token,
																			},
																		}
																	)

																	if (productRes && productRes.data) {
																		productInfo.id = productRes.data.id
																	}

																	addItem({
																		type: 'customProduct',
																		productInfo: productInfo,
																		price: price,
																		total: price,
																		priceCad: price,
																	})

																	removeModal('ConfiguratorModal')
																}}
																closeFunction={() => removeModal('ConfiguratorModal')}
																attributeInfo={savedAttribute}
																selectedPriceList={selectedPriceList}
															/>
														)}
													</div>
												),
												id: 'ConfiguratorModal',
												background: true,
												replaceModal: true,
												disposableModal: true,
											})
										} else {
											console.log('addModal not funciton?', addModal)
										}
									}}
								>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ marginRight: '3px' }}>
										<path fill="none" d="M0 0h24v24H0z" />
										<path d="M17 19h2v-5h-9V5H5v2h2v2H5v2h3v2H5v2h2v2H5v2h2v-2h2v2h2v-3h2v3h2v-2h2v2zm-5-7h8a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v8z" />
									</svg>
									Lampade
								</div>
								<div
									className="actions-configurator-option"
									onClick={async () => {
										let id = window.prompt('Inserire ID')

										if (id && (id.length === 7 || id.length === 10)) {
											let productRes = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/configurator-products/${id}`, {
												headers: {
													authorization: token,
												},
											})

											// console.log(productRes)

											if (productRes && productRes.data) {
												//
												addItem({
													type: 'customProduct',
													productInfo: productRes.data,
													price: productRes.data.price,
													total: productRes.data.price,
													priceCad: productRes.data.price,
												})
												toast.success('Prodotto caricato')
											} else {
												toast.error('ID non trovato')
											}

											// console.log(productRes)
										} else {
											toast.error('ID non valido')
										}
									}}
								>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ marginRight: '3px' }}>
										<path fill="none" d="M0 0h24v24H0z" />
										<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11H7v2h4v4h2v-4h4v-2h-4V7h-2v4z" />
									</svg>
									ID
								</div>
							</div>
							<div className="zone-actions-configurator">
								<div
									className="actions-configurator-option"
									onClick={() => {
										if (typeof addModal === 'function') {
											// let savedAttribute = retrieveSavedAttributes()

											addModal({
												modal: (
													<div style={{ width: '85vw', height: '85vh' }}>
														<NewConfigurator
															mode="led_strip"
															closeFunction={() => removeModal('ConfiguratorModal')}
															exportConfiguration={async (productData, prices) => {
																//
																// console.log(steps)
																let productInfo = parseCustomProductNew(productData)

																productInfo.type = 'customStrip'

																let price = 0
																if (Array.isArray(prices)) {
																	prices.forEach((p) => {
																		price += p
																	})
																}

																let productRes = await axios.post(
																	`${process.env.REACT_APP_SERVER_URL}/api/configurator-products/`,
																	{
																		price: price,
																		...productInfo,
																	},
																	{
																		headers: {
																			authorization: token,
																		},
																	}
																)

																if (productRes && productRes.data) {
																	productInfo.id = productRes.data.id
																}

																addItem({
																	type: 'customProductNew',
																	productInfo: productInfo,
																	price: price,
																	total: price,
																	priceCad: price,
																})
																removeModal('ConfiguratorModal')
															}}
														/>
													</div>
												),
												id: 'ConfiguratorModal',
												background: true,
												replaceModal: true,
												disposableModal: true,
											})
										} else {
											console.log('addModal not funciton?', addModal)
										}
									}}
								>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ marginRight: '3px' }}>
										<path fill="none" d="M0 0h24v24H0z" />
										<path d="M17 19h2v-5h-9V5H5v2h2v2H5v2h3v2H5v2h2v2H5v2h2v-2h2v2h2v-3h2v3h2v-2h2v2zm-5-7h8a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v8z" />
									</svg>
									Strisce LED TEST
								</div>
								<div
									className="actions-configurator-option"
									onClick={() => {
										if (typeof addModal === 'function') {
											// let savedAttribute = retrieveSavedAttributes()

											addModal({
												modal: (
													<div style={{ width: '85vw', height: '85vh' }}>
														<NewConfigurator
															mode="profile"
															closeFunction={() => removeModal('ConfiguratorModal')}
															exportConfiguration={async (productData, prices) => {
																//
																// console.log(steps)
																let productInfo = parseCustomProductNew(productData)

																productInfo.type = 'customProfile'

																let price = 0
																if (Array.isArray(prices)) {
																	prices.forEach((p) => {
																		price += p
																	})
																}

																let productRes = await axios.post(
																	`${process.env.REACT_APP_SERVER_URL}/api/configurator-products/`,
																	{
																		price: price,
																		...productInfo,
																	},
																	{
																		headers: {
																			authorization: token,
																		},
																	}
																)

																if (productRes && productRes.data) {
																	productInfo.id = productRes.data.id
																}

																addItem({
																	type: 'customProductNew',
																	productInfo: productInfo,
																	price: price,
																	total: price,
																	priceCad: price,
																})
																removeModal('ConfiguratorModal')
															}}
														/>
													</div>
												),
												id: 'ConfiguratorModal',
												background: true,
												replaceModal: true,
												disposableModal: true,
											})
										} else {
											console.log('addModal not funciton?', addModal)
										}
									}}
								>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ marginRight: '3px' }}>
										<path fill="none" d="M0 0h24v24H0z" />
										<path d="M17 19h2v-5h-9V5H5v2h2v2H5v2h3v2H5v2h2v2H5v2h2v-2h2v2h2v-3h2v3h2v-2h2v2zm-5-7h8a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v8z" />
									</svg>
									Taglio Profili TEST
								</div>
								<div
									className="actions-configurator-option"
									onClick={() => {
										if (typeof addModal === 'function') {
											// let savedAttribute = retrieveSavedAttributes()
											addModal({
												modal: (
													<div style={{ width: '85vw', height: '85vh' }}>
														<NewConfigurator
															mode="lamp"
															closeFunction={() => removeModal('ConfiguratorModal')}
															exportConfiguration={async (productData, prices) => {
																//
																// console.log(steps)
																let productInfo = parseCustomProductNew(productData)

																productInfo.type = 'customLamp'

																let price = 0
																if (Array.isArray(prices)) {
																	prices.forEach((p) => {
																		price += p
																	})
																}

																let productRes = await axios.post(
																	`${process.env.REACT_APP_SERVER_URL}/api/configurator-products/`,
																	{
																		price: price,
																		...productInfo,
																	},
																	{
																		headers: {
																			authorization: token,
																		},
																	}
																)

																if (productRes && productRes.data) {
																	productInfo.id = productRes.data.id
																}

																addItem({
																	type: 'customProductNew',
																	productInfo: productInfo,
																	price: price,
																	total: price,
																	priceCad: price,
																})
																removeModal('ConfiguratorModal')
															}}
														/>
													</div>
												),
												id: 'ConfiguratorModal',
												background: true,
												replaceModal: true,
												disposableModal: true,
											})
										} else {
											console.log('addModal not funciton?', addModal)
										}
									}}
								>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ marginRight: '3px' }}>
										<path fill="none" d="M0 0h24v24H0z" />
										<path d="M17 19h2v-5h-9V5H5v2h2v2H5v2h3v2H5v2h2v2H5v2h2v-2h2v2h2v-3h2v3h2v-2h2v2zm-5-7h8a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v8z" />
									</svg>
									Lampade TEST
								</div>
								<div
									className="actions-configurator-option"
									onClick={async () => {
										let id = window.prompt('Inserire ID')

										if (id && (id.length === 9 || id.length === 12)) {
											let productRes = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/configurator-products/${id}`, {
												headers: {
													authorization: token,
												},
											})

											if (productRes && productRes.data) {
												//
												addItem({
													type: 'customProduct',
													productInfo: productRes.data,
													price: productRes.data.price,
													total: productRes.data.price,
													priceCad: productRes.data.price,
												})
												toast.success('Prodotto caricato')
											} else {
												toast.error('ID non trovato')
											}

											// console.log(productRes)
										} else {
											toast.error('ID non valido')
										}
									}}
								>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ marginRight: '3px' }}>
										<path fill="none" d="M0 0h24v24H0z" />
										<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11H7v2h4v4h2v-4h4v-2h-4V7h-2v4z" />
									</svg>
									ID Test
								</div>
								<div
									className="actions-configurator-option"
									onClick={async () => {
										let id = window.prompt('Inserire Code')

										if (id) {
											let productRes = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/get-configuration/from-cod/${id}`, {
												headers: {
													authorization: token,
												},
											})

											if (productRes && productRes.data) {
												//
												if (productRes.data.info) {
													let steps = productRes.data.info[0]
													let lengths = productRes.data.lengths

													let importData = { steps: steps, lengths: lengths }

													if (typeof addModal === 'function') {
														// let savedAttribute = retrieveSavedAttributes()
														addModal({
															modal: (
																<div style={{ width: '85vw', height: '85vh' }}>
																	<NewConfigurator
																		importData={importData}
																		mode="lamp"
																		closeFunction={() => removeModal('ConfiguratorModal')}
																		exportConfiguration={async (productData, prices) => {
																			let productInfo = parseCustomProductNew(productData)

																			productInfo.type = 'customLamp'

																			let price = 0
																			if (Array.isArray(prices)) {
																				prices.forEach((p) => {
																					price += p
																				})
																			}

																			let productRes = await axios.post(
																				`${process.env.REACT_APP_SERVER_URL}/api/configurator-products/`,
																				{
																					price: price,
																					...productInfo,
																				},
																				{
																					headers: {
																						authorization: token,
																					},
																				}
																			)

																			if (productRes && productRes.data) {
																				productInfo.id = productRes.data.id
																			}

																			addItem({
																				type: 'customProductNew',
																				productInfo: productInfo,
																				price: price,
																				total: price,
																				priceCad: price,
																			})
																			removeModal('ConfiguratorModal')
																		}}
																	/>
																</div>
															),
															id: 'ConfiguratorModal',
															background: true,
															replaceModal: true,
															disposableModal: true,
														})
													} else {
														console.log('addModal not funciton?', addModal)
													}
												}
												// 	//
												// 	addItem({
												// 		type: 'customProduct',
												// 		productInfo: productRes.data,
												// 		price: productRes.data.price,
												// 		total: productRes.data.price,
												// 		priceCad: productRes.data.price,
												// 	})
												// 	toast.success('Prodotto caricato')
												// } else {
												// 	toast.error('ID non trovato')
											}

											// console.log(productRes)
										} else {
											toast.error('ID non valido')
										}
									}}
								>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ marginRight: '3px' }}>
										<path fill="none" d="M0 0h24v24H0z" />
										<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11H7v2h4v4h2v-4h4v-2h-4V7h-2v4z" />
									</svg>
									CODE Test
								</div>
								{/* <div
									className="actions-configurator-option"
									onClick={async () => {
										let id = window.prompt('Inserire ID')

										if (id && (id.length === 7 || id.length === 10)) {
											let productRes = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/configurator-products/${id}`, {
												headers: {
													authorization: token,
												},
											})

											// console.log(productRes)

											if (productRes && productRes.data) {
												//
												addItem({
													type: 'customProduct',
													productInfo: productRes.data,
													price: productRes.data.price,
													total: productRes.data.price,
													priceCad: productRes.data.price,
												})
												toast.success('Prodotto caricato')
											} else {
												toast.error('ID non trovato')
											}

											// console.log(productRes)
										} else {
											toast.error('ID non valido')
										}
									}}
								>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ marginRight: '3px' }}>
										<path fill="none" d="M0 0h24v24H0z" />
										<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11H7v2h4v4h2v-4h4v-2h-4V7h-2v4z" />
									</svg>
									ID
								</div> */}
							</div>
						</label>
						<label className="zone-product-entry zone-product-footer medium-large"></label>
						<label className="zone-product-entry zone-product-footer medium">{pricesSum.toFixed(2)} €</label>
						<label className="zone-product-entry zone-product-footer medium">
							{pricesTotal - pricesSum > 0 ? '+' : ''}
							{(pricesTotal - pricesSum).toFixed(2)} €
						</label>
						<label className="zone-product-entry zone-product-footer medium"></label>
						<label className="zone-product-entry zone-product-footer medium">{pricesTotal.toFixed(2)} € + IVA</label>
						{/* <label className="zone-product-entry drag"></label> */}
					</div>
					<div className="zone-product-footer">
						<div className="zone-product-entry small"></div>
						<div className="zone-product-entry grow">
							Totale Watt Richiesti: {totalWatt}&nbsp;&nbsp;-&nbsp;&nbsp;Watt Richiesti Lampade: {totalWattCustom}
							<div style={{ marginTop: '10px' }}>
								<Select
									menuPortalTarget={document.body}
									styles={{ menuPortal: (base) => ({ ...base, zIndex: 750 }) }}
									options={powerSupplies.map((product) => ({ product: product, label: product.name, value: product.product_id }))}
									placeholder="Alimentatore..."
									onChange={(value) => {
										// handleItemInput(i, 'profileCover', value.value)
										const items = [...zone.items]

										// value.product?.attributes?.watt

										items.push({ id: uuidv4(), quantity: 1, name: '', info: '', price: 0.0, length: 1, discount: '' })

										let index = items.length - 1
										let product = value.product

										items[index].quantity = Math.ceil(voltageWatts[value.product?.attributes?.volt] / value.product?.attributes?.watt)

										items[index].name = productLabel(product)

										items[index].product = product
										items[index] = calculateProductPrice(product, items[index], null, false, selectedPriceList)

										handleInput('items', items)
									}}
								/>
							</div>
						</div>
					</div>
					{/* zone.items && zone.items.length < 1 */}
				</div>
				{provided && <DragElement provided={provided} />}
				<RemoveElement element={zone} elementIndex={elementIndex} />
				<CopyElement element={zone} elementIndex={elementIndex} />
				<RefreshPrices refreshPrices={refreshPrices} />
			</div>
		</div>
	)
}
